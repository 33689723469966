import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet, Linking } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import globalStyles from './Styles';
import moment from 'moment';
import AlertDialog from './AlertDialog';
import { deleteSlot } from '../store/actions/adminActions';

export const SessionCard = ({ session, navigation, token, deleteSlot, action, setAction, user }) => {
    const role = token?.split(" ")[0]
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteSlot({ slotId: session._id })
        hideModal()
        setAction(!action)
    }

    return (
        <>
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete this session?`} okText={"CONFIRM"} />
            <Card style={defaultStyles.card}>
                <Card.Title style={{ fontWeight: "bold" }} title={"Training Session"} />
                <Card.Content>
                    <Text variant="bodyMedium">{session.additionalInfo}</Text>
                    <Text variant="bodyLarge">{moment(session.start).add(-1, "hour").format("DD/MM/YYYY")} @ {moment(session.start).add(-1, "hour").format("hh:mm a")} to {moment(session.end).add(-1, "hour").format("hh:mm a")}</Text>
                </Card.Content>
                <Card.Actions>
                    {role === "Super" && <>
                        <IconButton icon="qrcode-scan" onPress={() => navigation.navigate("Scanner", { slotId: session._id })} />
                        <IconButton icon="pencil" onPress={() => navigation.navigate("EditSession", { slot: session, academy: session.academy })} />
                        <IconButton icon="delete" onPress={showModal} />
                    </>}
                    <IconButton icon="map-marker-radius" onPress={() => Linking.openURL(session.location)} />
                </Card.Actions>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteSlot
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionCard);