import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Menu, Modal, Portal, SegmentedButtons, TextInput } from 'react-native-paper';
import AppBar from '../../../AppBar';
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { getAcademies } from '../../../app/store/actions/userActions';
import { RefreshControl } from 'react-native';
import globalStyles from '../../../app/components/Styles';
import AcademyCard from '../../../app/components/AcademyCard';
import {
    StyleSheet,
    TouchableOpacity,
    Linking,
    Text,
    View,
    Platform,
    Image
} from 'react-native';

import { Dimensions } from 'react-native';
import { takeAttendance, takeExceptionalAttendance } from '../../../app/store/actions/superActions';
import { Camera, CameraView, useCameraPermissions } from 'expo-camera';
import QrReader from "modern-react-qr-reader"

export const AdminScanner = ({ route, takeAttendance, isLoading, isError, exceptionData, takeExceptionalAttendance }) => {

    const navigation = useNavigation();
    const [scanned, setScanned] = useState(false);
    const [segment, setSegment] = useState("trainee");
    const { slotId } = route.params;
    const [permission, requestPermission] = useCameraPermissions();
    const [facing, setFacing] = useState("back")
    const handleBarCodeScanned = (data) => {
        if (data) {
            console.log(data)
            takeAttendance({ slotId, userId: data, segment })
            setScanned(true);
            console.log(exceptionData)
        }
    };

    const handleExceptionalAttendance = (data) => {
        takeExceptionalAttendance({ slotId: exceptionData.slotId, userId: exceptionData.userId })
        setScanned(true);
    };

    const cameraRef = useRef(null);
    const handleScan = async () => {
        const uri = (await cameraRef.current.takePictureAsync())
        console.log(uri)
        const barcodeResult = await Camera.scanFromURLAsync(uri)
        console.log(barcodeResult)
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }} >
            <AppBar navigation={navigation} title={"Attendance Scanner"} />
            <View style={{ ...globalStyles.container, marginTop: 50, width: "100%" }}>
                {(!permission || isLoading) && <ActivityIndicator />}
                {!isLoading && <View>
                    <SegmentedButtons
                        value={segment}
                        onValueChange={setSegment}
                        style={{ marginBottom: 20, paddingHorizontal: 50 }}
                        buttons={[
                            {
                                value: 'trainee',
                                label: 'Trainee',
                            },
                            {
                                value: 'coach',
                                label: 'Coach',
                            },
                        ]}
                    />
                    {/* <BarcodeScannerComponent
                        width={screenWidth - 50}
                        height={screenWidth - 50}
                        onUpdate={(err, result) => {
                            if (scanned) return;
                            if (result) handleBarCodeScanned(result.text);
                            else setData("Not Found");
                        }}
                    /> */}
                    <View style={styles.container}>
                        {/* <CameraView ref={cameraRef} facing={facing} poster='https://miro.medium.com/v2/resize:fit:679/1*ngNzwrRBDElDnf2CLF_Rbg.gif' barcodeScannerEnabled={true}
                            barcodeScannerSettings={{
                                barcodeTypes: ["qr"],
                            }}
                            onBarcodeScanned={(result) => {
                                console.log(result)
                                if (scanned) return;
                                if (result) handleBarCodeScanned(result);
                                else setData("Not Found")
                            }} style={{ flex: 1 }}>
                            <View style={styles.buttonContainer}>

                            </View>
                        </CameraView> */}
                        <QrReader
                            onScan={(data) => scanned ? null : handleBarCodeScanned(data)}
                            style={{
                                height: 250,
                                width: 250,
                            }}
                            willReadFrequently 
                        />
                    </View>
                    {scanned && <Button mode="contained" style={{ marginTop: 10 }} onPress={() => setScanned(false)}> Tap So Scan Again</Button>}
                    {isError && !scanned && <Button mode="contained" style={{ marginTop: 10 }} onPress={handleExceptionalAttendance}> Exceptional Attendance</Button>}
                </View>}
                {/* {!permission?.granted && (
                    <View>
                        <Text style={{ textAlign: 'center' }}>We need your permission to show the camera</Text>
                        <Button mode="contained" style={{ marginTop: 10 }} onPress={requestPermission}> Grant Permission</Button>
                    </View>
                )} */}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 150,
    }
});

const mapStateToProps = (state) => ({
    isLoading: state?.wait?.isLoading,
    isError: state?.wait?.isError,
    exceptionData: state?.wait?.payload
});

const mapDispatchToProps = { takeAttendance, takeExceptionalAttendance };

export default connect(mapStateToProps, mapDispatchToProps)(AdminScanner);