import React, { useEffect, useState } from "react";
import { View, ScrollView, Text } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator, SegmentedButtons, Chip, ThemeProvider, DefaultTheme } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../../app/components/Styles";
import { Divider } from '@rneui/themed';
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput, TimePickerModal } from "react-native-paper-dates";
import DropDown from "react-native-paper-dropdown";
import AppBar from "../../../AppBar";
import { addSessions } from "../../../app/store/actions/superActions";
import { PaperSelect } from "react-native-paper-select";
import { getCoaches } from "../../../app/store/actions/userActions";

const AddSessions = ({ addSessions, getTournament, isLoading, teams, route }) => {
    const { academy, coaches } = route.params
    const navigation = useNavigation();
    const [formData, setFormData] = useState({
        academyId: academy._id,
        days: [],
        startDate: undefined,
        endDate: undefined,
        coachIds: [],
        additionalInfo: undefined,
        location: undefined,
        variant: "slots"
    })
    const [startTime, setStartTime] = useState({ hours: 12, minutes: 0 })
    const [endTime, setEndTime] = useState({ hours: 12, minutes: 0 })
    const [selectedCoaches, setSelectedCoaches] = useState([])

    const [visible, setVisible] = useState(false)
    const onDismiss = React.useCallback(() => {
        setVisible(false)
    }, [setVisible])

    const onConfirm = React.useCallback(
        ({ hours, minutes }) => {
            setVisible(false);
            setStartTime({ hours, minutes });
        },
        [setVisible]
    );

    const [visible1, setVisible1] = useState(false)
    const onDismiss1 = React.useCallback(() => {
        setVisible1(false)
    }, [setVisible1])

    const onConfirm1 = React.useCallback(
        ({ hours, minutes }) => {
            setVisible1(false);
            setEndTime({ hours, minutes });
        },
        [setVisible1]
    );

    const handleDaySelect = (day) => {
        let tempDays = formData.days
        if (tempDays.includes(day)) {
            tempDays = tempDays.filter((d) => d !== day)
        } else if (!tempDays.includes(day)) {
            tempDays.push(day)
        }
        setFormData({ ...formData, days: tempDays })
    }

    const handleChangeFormData = (name, val) => {
        setFormData({ ...formData, [name]: val })
    }

    const handleAddSessions = () => {
        let form = { ...formData, startDate: moment(formData.startDate).format("YYYY-MM-DD"), endDate: moment(formData.endDate).format("YYYY-MM-DD"), startTime: startTime, endTime: endTime }
        addSessions(form, navigation)
    };

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (

        <ScrollView>
            <AppBar navigation={navigation} title={"Add Training Sessions"} />
            <View style={{ padding: 30 }}>
                <SegmentedButtons
                    value={formData.variant}
                    onValueChange={val => handleChangeFormData("variant", val)}
                    style={{ marginBottom: 20, paddingHorizontal: 50 }}
                    buttons={[
                        {
                            value: 'slots',
                            label: 'Recurring',
                        },
                        {
                            value: 'slot',
                            label: 'One Time',
                        },
                    ]}
                />

                {formData.variant === "slots" ? <>
                    <View style={{ justifyContent: "center", flex: 2, flexDirection: "row", flexWrap: "wrap" }}>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(0)} onPress={() => handleDaySelect(0)}>Sunday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(1)} onPress={() => handleDaySelect(1)}>Monday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(2)} onPress={() => handleDaySelect(2)}>Tuesday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(3)} onPress={() => handleDaySelect(3)}>Wednesday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(4)} onPress={() => handleDaySelect(4)}>Thursday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(5)} onPress={() => handleDaySelect(5)}>Friday</Chip>
                        <Chip icon="calendar" style={{ marginEnd: 10, marginBottom: 5 }} showSelectedOverlay selected={formData.days.includes(6)} onPress={() => handleDaySelect(6)}>Saturday</Chip>
                    </View>


                    <ThemeProvider theme={{
                        ...DefaultTheme,
                        colors: {
                            ...DefaultTheme.colors,
                            primary: '#000B26',
                            onPrimary: "#ffffff",
                            surface: '#ffffff',
                        }
                    }}>
                        <DatePickerInput
                            locale="en-GB"
                            mode="outlined"
                            style={{ marginTop: 8 }}
                            label="Start Date"
                            value={formData.startDate}
                            onChange={(d) => handleChangeFormData("startDate", d)}
                        />
                    </ThemeProvider>

                    <ThemeProvider theme={{
                        ...DefaultTheme,
                        colors: {
                            ...DefaultTheme.colors,
                            primary: '#000B26',
                            onPrimary: "#ffffff",
                            surface: '#ffffff',
                        }
                    }}>
                        <DatePickerInput
                            locale="en-GB"
                            mode="outlined"
                            style={{ marginVertical: 4 }}
                            label="End Date"
                            value={formData.endDate}
                            onChange={(d) => handleChangeFormData("endDate", d)}
                            inputMode="end"
                        />
                    </ThemeProvider>
                </> : <>
                    <ThemeProvider theme={{
                        ...DefaultTheme,
                        colors: {
                            ...DefaultTheme.colors,
                            primary: '#000B26',
                            onPrimary: "#ffffff",
                            surface: '#ffffff',
                        }
                    }}>
                        <DatePickerInput
                            locale="en-GB"
                            mode="outlined"
                            style={{ marginTop: 8 }}
                            label="Start Date"
                            value={formData.startDate}
                            onChange={(d) => handleChangeFormData("startDate", d)}
                        />
                    </ThemeProvider>
                </>}

                <Button style={{ marginVertical: 8 }} onPress={() => setVisible(true)} uppercase={false} mode="outlined">
                    Pick start time
                </Button>

                <Button style={{ marginVertical: 8 }} onPress={() => setVisible1(true)} uppercase={false} mode="outlined">
                    Pick end time
                </Button>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#ffffff',
                        onPrimary: "#000B26",
                        onBackground: '#000B26',
                        secondaryContainer: '#ffffff',
                        onSecondary: "#000B26",
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff"
                    }
                }}>
                    <TimePickerModal
                        locale="en-GB"
                        visible={visible}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        hours={startTime.hours}
                        minutes={startTime.minutes}
                    />
                </ThemeProvider>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#ffffff',
                        onPrimary: "#000B26",
                        onBackground: '#000B26',
                        secondaryContainer: '#ffffff',
                        onSecondary: "#000B26",
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff"
                    }
                }}>
                    <TimePickerModal
                        locale="en-GB"
                        visible={visible1}
                        onDismiss={onDismiss1}
                        onConfirm={onConfirm1}
                        hours={endTime.hours}
                        minutes={endTime.minutes}
                    />
                </ThemeProvider>

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Additional Information"
                    value={formData.additionalInfo}
                    onChangeText={(text) => handleChangeFormData("additionalInfo", text)}
                />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Location URL (if different from default)"
                    value={formData.location}
                    onChangeText={(text) => handleChangeFormData("location", text)}
                />

                <PaperSelect
                    label="Coaches"
                    textInputStyle={{ marginTop: 10 }}
                    value={selectedCoaches[0]?.value}
                    onSelection={(value) => { handleChangeFormData("coachIds", value.selectedList.map((coach) => { return coach._id })); setSelectedCoaches(value.selectedList) }}
                    arrayList={coaches && coaches.map((coach) => {
                        return {
                            value: coach.name,
                            _id: coach.coach._id
                        }
                    })}
                    selectedArrayList={[...selectedCoaches]}
                    multiEnable={true}
                />

                <Button style={{ marginTop: 8 }} mode="contained" onPress={handleAddSessions}>
                    Add Slot{formData.variant === "slots" && "s"}
                </Button>
            </View >
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.wait?.isLoading,
});

const mapDispatchToProps = {
    addSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSessions);