import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteMatch } from '../store/actions/adminActions';
import globalStyles from './Styles';
import moment from 'moment';
import { followMatch, getMatches, getTournament } from "../store/actions/userActions";

export const SuggestedMatchCard = ({ match, navigation, token, deleteMatch, getMatches, getTournament, user, followMatch }) => {
    const role = token?.split(" ")[0]

    return (
        <Card style={defaultStyles.card} onPress={() => navigation.navigate("CreateMatch", {match, tournament: match.tournament})}>
            <View>
                <Card.Content>
                    <View style={grid.container}>
                        <View style={grid.leftColumn}>
                            <Text style={{ ...grid.textStyle }} >{match.teamA.name}</Text>
                        </View>
                        <View style={grid.centerColumn}>
                            <Text style={{ ...grid.textStyle }}> Vs. </Text>
                        </View>
                        <View style={grid.rightColumn}>
                            <Text style={{ ...grid.textStyle }}>{match.teamB.name}</Text>
                        </View>
                    </View>
                </Card.Content>
            </View>
            {role && role === "Super" &&
                <Card.Actions>
                    <Divider style={{ marginBottom: 7 }} />
                    <Button onPress={() => navigation.navigate("CreateMatch", {match, tournament: match.tournament})}>Create</Button>
                </Card.Actions>
            }
        </Card>
    )
}

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "3%",
    },
    textStyle: {
        fontSize: 13
    },
    leftColumn: {
        flex: 1,
        alignItems: 'flex-end',
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'flex-start',
    },
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedMatchCard);