import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Button, TextInput } from 'react-native-paper';
import { enrollTrainee } from '../../../app/store/actions/authActions';
import globalStyles from '../../../app/components/Styles';
import AppBar from '../../../AppBar';
import { DatePickerInput } from 'react-native-paper-dates';
import DropDown from 'react-native-paper-dropdown';
import { ScrollView } from 'react-native-gesture-handler';
import moment, { isMoment } from 'moment';

export const Enroll = ({ user, isLoading, enrollTrainee }) => {

    const navigation = useNavigation();
    useEffect(() => {
        if (user.trainee) navigation.navigate("AcademyHome")
    }, [user])

    const [userData, setUserData] = React.useState({
        gender: undefined,
        birthdate: undefined,
    })

    const { gender, birthdate } = userData

    const handleEnroll = async () => {
        if (!gender || !birthdate) {
            alert('Please fill all fields')
        } else {
            enrollTrainee({
                creation: {
                    gender,
                    birthdate: moment(birthdate).format("YYYY-MM-DD")
                }
            })
        }
    }

    const [showDropDown, setShowDropDown] = useState(false);

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View>
            <AppBar navigation={navigation} title={"Enroll in Academies"} />
            <ScrollView style={{ padding: 30 }} contentContainerStyle={{ justifyContent: "center" }}>
                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    style={globalStyles.input}
                    label={"Gender*"}
                    mode={"outlined"}
                    visible={showDropDown}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={gender}
                    setValue={(val) => setUserData({ ...userData, gender: val })}
                    list={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" }
                    ]}
                />
                <DatePickerInput
                    locale="en-GB"
                    mode="outlined"
                    style={{ marginTop: 8 }}
                    label="Birth Date"
                    value={birthdate}
                    onChange={(d) => setUserData({ ...userData, birthdate: d})}
                    inputMode="start"
                />
                <Button mode="contained" style={{ marginTop: 10 }} onPress={async () => await handleEnroll()}>
                    Enroll in academies program
                </Button>
            </ScrollView>
        </View>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading
});

const mapDispatchToProps = { enrollTrainee };

export default connect(mapStateToProps, mapDispatchToProps)(Enroll);