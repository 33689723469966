import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, CART, CART_SUCCESS, CART_FAIL, WALLET, WALLET_SUCCESS, WALLET_FAIL, WAIT, WAIT_SUCCESS, WAIT_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import AsyncStorage from '@react-native-async-storage/async-storage';
import registerForPushNotificationsAsync from "../../components/DeviceToken";
import * as Device from "expo-device";

export const loadUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  });
  let user = await AsyncStorage.getItem("ROARUser");
  if (user) {
    user = JSON.parse(user);
    console.log(user)
    return dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
      token: await AsyncStorage.getItem("ROARToken")
    });
  } else {
    return dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  await AsyncStorage.removeItem("ROARUser");
  await AsyncStorage.removeItem("ROARToken");
  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const signUp = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  postRequest(data, undefined, undefined, undefined, endpoints.auth.signUp)
    .then(async (response) => {
      const { data } = response;
      await AsyncStorage.setItem("ROARUser", JSON.stringify(data.payload));
      await AsyncStorage.setItem("ROARToken", data.token);
      console.log(data.message)
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });

  return dispatch({
    type: LOGIN_SUCCESS,
    payload: data.payload,
    token: data.token
  });
}

export const loginUser = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  const info = {
    login: data.login,
    password: data.password
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.loginUser)
    .then(async (response) => {
      const { data } = response;
      await AsyncStorage.setItem("ROARUser", JSON.stringify(data.payload));
      await AsyncStorage.setItem("ROARToken", data.token);
      console.log(data.message)
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.message);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
}

export const resetPassword = (data) => (dispatch) => {
  dispatch({ type: WAIT });

  const info = {
    email: data.email
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.resetPassword)
    .then(async (response) => {
      const { data } = response;
      alert(data.message)
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.message);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}

export const enrollTrainee = (data) => (dispatch, useState) => {
  dispatch({ type: LOGIN });
  const token = useState().auth?.token

  postRequest(data, undefined, undefined, token, endpoints.auth.enroll)
    .then(async (response) => {
      const { data } = response;
      const deviceToken = await registerForPushNotificationsAsync()
      postRequest({ deviceToken }, undefined, undefined, data.token, endpoints.user.registerNotification)
        .then(async (response) => {
          const { data } = response;
          await AsyncStorage.setItem("ROARUser", JSON.stringify(data.payload));
          await AsyncStorage.setItem("ROARToken", data.token);
          console.log(data.message)
          return dispatch({
            type: LOGIN_SUCCESS,
            payload: data.payload,
            token: data.token
          });
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.message);
          return dispatch({
            type: LOGIN_FAIL,
          });
        });
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.message);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};