import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";

export const addOlympiad = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.olympiad)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const listOlympiads = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState().auth?.token

    getRequest(data, undefined, token, endpoints.admin.olympiads)
        .then(async (response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const deleteOlympiad = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.olympiad)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addTournament = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.tournament)
        .then(async (response) => {
            navigation && navigation.navigate("AdminHome")
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteTournament = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.tournament)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getSuggestedMatches = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState().auth?.token

    getRequest(data, undefined, token, endpoints.admin.suggestedMatches)
        .then(async (response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const addMatch = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.match)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteMatch = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.match)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addTeam = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.team)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editTeam = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.admin.team)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addTeamOlympiad = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.teamOlympiad)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addPlayersToTeam = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.addPlayers)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editPlayer = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.admin.player)
        .then(async (response) => {
            alert(response.data?.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deletePlayer = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.player)
        .then(async (response) => {
            alert(response.data?.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};
export const deleteTeam = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.team)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addSport = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.sport)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteMatchEvent = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.matchUpdate)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editMatchEvent = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.admin.matchUpdate)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const updateMatch = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.admin.matchUpdate)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editMatch = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.admin.match)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            console.log(err.response.data.message);

            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteSlot = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.slot)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteSport = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.admin.singleSport)
        .then(async (response) => {
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};