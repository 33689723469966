import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Menu, TextInput } from 'react-native-paper';
import { getOlympiads, getTournaments } from '../../app/store/actions/userActions';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import globalStyles from "../../app/components/Styles";
import TournamentCard from '../../app/components/TournamentCard';
import AppBar from '../../AppBar';
import { TabScreen, Tabs } from 'react-native-paper-tabs';
import OlympicsCard from '../../app/components/OlympicsCard';
import { AntDesign } from '@expo/vector-icons';

export const Tournaments = ({ user, getTournaments, connectWebSocket, olympicsLoading, tournaments, isLoading, getOlympiads, olympics }) => {

    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const [searchBar, setSearchBar] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [action, setAction] = React.useState(false)


    useEffect(() => {
        getTournaments({ page: 1, limit: 100, name: search })
    }, [])

    useEffect(() => {
        setSearch("")
        getTournaments({ page: 1, limit: 100, name: search })
    }, [action])

    const handleSearchFilter = () => {
        getTournaments({ page: 1, limit: 100, name: search })
    }

    const openMenu = () => {
        setSearchBar(true)
    }

    const closeMenu = () => {
        setSearchBar(false)
    }

    return (
        <>
            <AppBar title="Tournaments" noBack action={<>
                {user?.type === "Super" && <Appbar.Action icon="plus" onPress={() => navigation.navigate("CreateTournament")} />}
                <Menu
                    visible={searchBar}
                    onDismiss={closeMenu}
                    style={{ width: 200 }}
                    anchor={<Appbar.Action icon="magnify" id="search-menu" onPress={openMenu} />}>
                    <TextInput
                        mode="outlined"
                        label="Search"
                        value={search}
                        onChangeText={text => setSearch(text)}
                        right={<TextInput.Icon onPress={handleSearchFilter} icon="magnify" />}
                    />
                </Menu></>} />
            <ScrollView refreshControl={<RefreshControl onRefresh={() => getTournaments({ page: 1, limit: 100, name: "" })} />}>
                <View style={{ ...globalStyles.container, marginTop: 5, paddingHorizontal: 30 }}>
                    {
                        isLoading && (
                            <View style={globalStyles.container}>
                                <ActivityIndicator />
                            </View>
                        )
                    }
                    {
                        tournaments?.map((tournament, i) => {
                            return <TournamentCard key={i} navigation={navigation} tournament={tournament} action={action} setAction={setAction} />
                        })
                    }
                    {
                        tournaments?.length === 0 && (
                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                <AntDesign name="dropbox" size={100} color="black" />
                                <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                            </View>
                        )
                    }
                </View>
            </ScrollView>
        </>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    tournaments: state?.tournaments?.results?.docs,
    isLoading: state?.tournaments?.isLoading,
    olympics: state?.records?.olympics?.docs,
    olympicsLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getTournaments,
    connectWebSocket,
    getOlympiads
};

export default connect(mapStateToProps, mapDispatchToProps)(Tournaments);