import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { Profile, Login, Matches, AdminHome, CreateTournament, CreateSport, CreateOlympics, CreateAcademy, ListSports, CreateAdmin } from "../Pages";
import { NavigationContainer } from "@react-navigation/native";

const Stack = createStackNavigator();

export default function AdminRouter({ }) {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="AdminHome">
        <Stack.Screen name="AdminHome" options={{ headerShown: false }} component={AdminHome} />
        <Stack.Screen name="CreateTournament" options={{ headerShown: false }} component={CreateTournament} />
        <Stack.Screen name="CreateSport" options={{ headerShown: false }} component={CreateSport} />
        <Stack.Screen name="ListSports" options={{ headerShown: false }} component={ListSports} />
        <Stack.Screen name="CreateOlympics" options={{ headerShown: false }} component={CreateOlympics} />
        <Stack.Screen name="CreateAcademy" options={{ headerShown: false }} component={CreateAcademy} />
        <Stack.Screen name="CreateAdmin" options={{ headerShown: false }} component={CreateAdmin} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
