import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import globalStyles from './Styles';
import moment from 'moment';
import AlertDialog from './AlertDialog';
import { deleteAcademy } from '../store/actions/superActions';

export const AcademyCard = ({ academy, navigation, token, user, deleteAcademy, action, setAction }) => {
    const role = user?.type

    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteAcademy({ academyId: academy._id })
        hideModal()
        setAction(!action)
    }

    return (
        <>
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete this academy?`} okText={"CONFIRM"} />
            <Card style={defaultStyles.card} onPress={() => navigation.navigate("Academy", { academyId: academy._id })}>
                <Card.Cover source={{ uri: "https://www.lboro.ac.uk/media/wwwlboroacuk/external/content/newsandevents/news/2020/march/sport-main.jpg" }} />
                <Card.Title titleStyle={{ fontWeight: "bold" }} title={academy.title} />
                <Card.Content>
                    <Text variant="bodyMedium">{academy.description}</Text>
                </Card.Content>
                {role && role === "Super" &&
                    <Card.Actions>
                        <Divider style={{ marginBottom: 7 }} />
                        <Button onPress={showModal} icon="delete">Delete</Button>
                    </Card.Actions>
                }
            </Card>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteAcademy
};

export default connect(mapStateToProps, mapDispatchToProps)(AcademyCard);