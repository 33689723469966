import React, { useEffect, useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text, ActivityIndicator, Menu, Appbar } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../../app/components/Styles";
import { Divider } from '@rneui/themed';
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../../AppBar";
import DropDown from "react-native-paper-dropdown";
import { editCoach } from "../../../app/store/actions/superActions";

const EditCoach = ({ editCoach, isLoading, route }) => {

    const navigation = useNavigation();
    const { coach } = route.params
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const [userData, setUserData] = useState({
        salary: `${coach.coach.salary || "0"}`
    });

    const handleCreateCoach = () => {
        editCoach({ salary: parseInt(userData.salary), coachId: coach.coach._id }, navigation)
    };


    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View>
            <AppBar navigation={navigation} title={`Edit ${coach.name}`} />
            <ScrollView style={{ padding: 30 }} contentContainerStyle={{ justifyContent: "center" }}>
                <TextInput
                    mode="outlined"
                    label="Salary"
                    inputMode="numeric"
                    value={userData.salary}
                    onChangeText={(text) => setUserData({ ...userData, salary: text })}
                    style={{ marginVertical: 4 }}
                />
                <Button mode="contained" style={{ marginVertical: 4 }} onPress={handleCreateCoach}>
                    Edit Coach
                </Button>
            </ScrollView>
        </View>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = {
    editCoach
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCoach);