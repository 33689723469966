import React from 'react';
import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Modal, Portal, TextInput } from 'react-native-paper';
import { sendNotification } from '../../app/store/actions/superActions';
import { connect } from 'react-redux';

const AdminHome = ({ navigation, sendNotification }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
  const [message, setMessage] = useState("")

  const handleSendNotification = async () => {
    try {
      sendNotification({ message })
      hideModal()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <View>
        <Portal>
          <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
            <TextInput
              mode="outlined"
              style={{ marginVertical: 4, width: "100%" }}
              label="Notification Message"
              value={message}
              onChangeText={setMessage}
              required
            />

            <Button
              mode="contained"
              style={styles.button}
              onPress={handleSendNotification}
            >
              Send
            </Button>
          </Modal>
        </Portal>
      </View>
      <Button
        mode="contained"
        style={styles.button}
        onPress={showModal}
      >
        Send Notification
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('CreateAdmin')}
      >
        Create Admin (Matches)
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('CreateOlympics')}
      >
        Create Sports Day
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('CreateTournament')}
      >
        Create Tournament
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('CreateSport')}
      >
        Create Sport
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('ListSports')}
      >
        List Sports
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={() => navigation.navigate('CreateAcademy')}
      >
        Create Academy
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginVertical: 10,
    width: '80%',
  },
});

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  sendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
