import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Linking, Image, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Avatar, Button, Card, Divider } from 'react-native-paper';
import AlertDialog from '../../app/components/AlertDialog';
import { logout } from '../../app/store/actions/authActions';
import globalStyles from '../../app/components/Styles';
import AppBar from '../../AppBar';
import roarImage from "../../app/components/images/ROAR.png";
import { getNotifications } from '../../app/store/actions/userActions';
import { ScrollView } from 'react-native-gesture-handler';
import moment from 'moment';

export const NotificationsPage = ({ notifications, isLoading, getNotifications }) => {

    const navigation = useNavigation();

    useEffect(() => {
        getNotifications({ page: 1, limit: 10 })
    }, [])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }


    return (
        <>
            <AppBar navigation={navigation} title="Notifications" />
            <ScrollView refreshControl={<RefreshControl onRefresh={() => getNotifications({ page: 1, limit: 10 })} />}>
                <View style={{ padding: 15 }}>
                    {notifications?.docs.map((notification) => {
                        return (
                            <Card style={globalStyles.card} >
                                <Card.Title
                                    title={"Notification"}
                                    subtitle={moment(notification.createdAt).format("lll")}
                                    left={(props) => <Avatar.Icon {...props} icon="bell" />}
                                />
                                <Card.Content>
                                    <Text>{notification.message}</Text>
                                </Card.Content>
                            </Card>
                        )
                    })}
                </View>
            </ScrollView>
        </>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    notifications: state?.records?.notifications,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);