import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteMatchEvent } from '../store/actions/adminActions';
import globalStyles from './Styles';
import moment from 'moment';
import { followMatch, getMatches, getTournament } from "../store/actions/userActions";
import AlertDialog from './AlertDialog';

export const MatchEventCard = ({ event, match, navigation, token, deleteMatchEvent, action, setAction }) => {
    const role = token?.split(" ")[0]

    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteMatchEvent({ eventId: event._id })
        hideModal()
        setAction(!action)
    }

    const handleIcon = (type) => {
        switch (type) {
            case "Goal": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='green' icon="soccer" />
            case "Assist": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='black' icon="shoe-cleat" />
            case "Own Goal": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="soccer" />
            case "Yellow Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='yellow' icon="cards" />
            case "Second Yellow Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="cards" />
            case "Red Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="cards" />
        }
    }

    return (
        <>
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete this event?`} okText={"CONFIRM"} />
            <Card style={globalStyles.card}>
                <View style={grid.container}>
                    <View style={grid.leftColumn}>
                        {event.team._id === match.teamA._id && <>{handleIcon(event.type === "Caution" ? event.cautionType : event.type)}<Text>{event.player ? event.player.name : "<TBD>"}</Text></>}
                    </View>
                    <View style={grid.centerColumn}>
                        <Text>{event.time}'</Text>
                    </View>
                    <View style={grid.rightColumn}>
                        {event.team._id === match.teamB._id && <><Text>{event.player ? event.player.name : "<TBD>"}</Text>{handleIcon(event.type === "Caution" ? event.cautionType : event.type)}</>}
                    </View>
                </View>
                <Card.Actions>
                    {!event.player && <IconButton icon="pencil" onPress={() => navigation.navigate("EditMatchEvent", { match, event })} />}
                    <IconButton icon="delete" onPress={showModal} />
                </Card.Actions>
            </Card>
        </>
    )
}

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300,
        padding: "3%",
        marginBottom: 5
    },
    textStyle: {
        fontSize: 25
    },
    leftColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-start",
        flexDirection: "row",
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-end",
        flexDirection: "row",
    },
    item: {
        margin: 0,
    },
    badge: {
        position: 'absolute',
        top: -2,
        right: -6,
    },
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteMatchEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchEventCard);