import * as React from 'react';
import { Avatar, Badge, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteMatch } from '../store/actions/adminActions';
import globalStyles from './Styles';
import moment from 'moment';
import { followMatch, getMatches, getTournament } from "../store/actions/userActions";
import AlertDialog from './AlertDialog';

export const KOCard = ({ match, navigation, token, deleteMatch, getMatches, getTournament, user, followMatch, action, setAction }) => {
    const role = token?.split(" ")[0]
    const [matchDate, setMatchDate] = React.useState(moment(match?.date).add(-3, "hours"))
    const [time, setTime] = React.useState(moment(moment() - moment(match?.date)).minutes());
    const [live, setLive] = React.useState(moment().isAfter(matchDate) && match?.started)

    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteMatch({ matchId: match._id })
        hideModal()
        setAction(!action)
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            match?.started && setTime(moment(moment() - moment(match?.startedAt)).minutes())
            setLive(!match?.ended && match?.started)
        }, 100);
        return () => {
            clearInterval(interval);
        };
    })

    return (
        <Card style={{ ...defaultStyles.card, width: match.round === "Final" ? 200 : 150, height: match.round === "Final" ? 200 : 150, justifyContent: "center", padding: 2, margin: 5 }} onPress={() => navigation.navigate("Match", { matchId: match._id })}>
            <Card.Content>
                <View style={grid.container}>
                    <View style={grid.leftColumn}>
                        {match.ended && match.scoreA > match.scoreB && match.round === "Final" && <Avatar.Icon style={{ backgroundColor: "transparent" }} color="#B39961" icon="trophy" size={35} />}
                    </View>
                    <View style={grid.centerColumn}>
                        {!match.ended && match.round === "Final" && <Avatar.Icon style={{ backgroundColor: "transparent" }} color="#B39961" icon="trophy" size={40} />}
                    </View>
                    <View style={grid.rightColumn}>
                        {match.ended && match.scoreA < match.scoreB && match.round === "Final" && <Avatar.Icon style={{ backgroundColor: "transparent" }} color="#B39961" icon="trophy" size={35} />}

                    </View>
                </View>
                <View style={grid.container}>
                    <View style={grid.leftColumn}>
                        <Avatar.Icon style={{ backgroundColor: match.teamA.color }} size={30} icon="shield-star" />
                    </View>
                    <View style={grid.centerColumn}>

                    </View>
                    <View style={grid.rightColumn}>
                        <Avatar.Icon style={{ backgroundColor: match.teamB.color }} size={30} icon="shield-star" />
                    </View>
                </View>
                <View style={grid.container}>
                    <View style={grid.leftColumn}>
                        <Text style={{ ...grid.textStyle, fontWeight: match.scoreA > match.scoreB ? "bold" : "normal", textDecorationLine: match.ended ? (match.scoreA > match.scoreB ? "normal" : "line-through") : "normal" }} >{match.teamA.name.slice(0, 3).toUpperCase()}</Text>
                    </View>
                    <View style={grid.centerColumn}>

                    </View>
                    <View style={grid.rightColumn}>
                        <Text style={{ ...grid.textStyle, fontWeight: match.scoreA < match.scoreB ? "bold" : "normal", textDecorationLine: match.ended ? (match.scoreA < match.scoreB ? "normal" : "line-through") : "normal" }}>{match.teamB.name.slice(0, 3).toUpperCase()}</Text>
                    </View>
                </View>
                <View style={grid.container}>
                    <View style={grid.leftColumn}>
                        <Text style={{ ...grid.textStyle, fontWeight: match.scoreA > match.scoreB ? "bold" : "normal" }} >{match?.started ? match.scoreA : ""}</Text>
                    </View>
                    <View style={grid.centerColumn}>
                        <Text style={{ ...grid.textStyle }}>{!match?.started ? `${moment(matchDate).format("HH:mm")}` : `-`}</Text>
                    </View>
                    <View style={grid.rightColumn}>
                        <Text style={{ ...grid.textStyle, fontWeight: match.scoreA < match.scoreB ? "bold" : "normal" }}>{match?.started ? match.scoreB : ""}</Text>
                    </View>
                </View>
                <View style={{ ...grid.container, marginTop: 10 }}>
                    <View style={grid.centerColumn}>
                        <Text style={{ ...grid.textStyle, ...(live ? { color: "green" } : {}) }}>{match?.round}</Text>
                    </View>
                </View>
            </Card.Content>
            {live && <Badge size={30}
                style={{ position: "absolute", top: -15, right: -15, fontSize: 15, backgroundColor: "green" }}
            >{time}</Badge>}
        </Card>
    )
}

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "3%"
    },
    textStyle: {
        fontSize: 13,
        textAlign: "center"
    },
    leftColumn: {
        flex: 1,
        alignItems: 'flex-end',
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'flex-start',
    },
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteMatch,
    getMatches,
    getTournament,
    followMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(KOCard);