import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Menu, Modal, Portal, TextInput } from 'react-native-paper';
import roarImage from "../../app/components/images/ROAR.png";
import AppBar from '../../AppBar';
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { getAcademy } from '../../app/store/actions/userActions';
import { RefreshControl } from 'react-native';
import globalStyles from '../../app/components/Styles';
import AcademyCard from '../../app/components/AcademyCard';
import SessionCard from '../../app/components/SessionCard';
import { Agenda, Calendar, AgendaEntry } from 'react-native-calendars';
import moment from 'moment';
import { StyleSheet, TouchableOpacity } from 'react-native';

export const AcademyAgenda = ({ user, trainee, route }) => {

    const navigation = useNavigation();
    const { slots, academy, coaches } = route.params
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
    const [marked, setMarked] = useState({});
    const [agendaItems, setAgendaItems] = useState({});
    const [currMonth, setCurrMonth] = useState({ year: moment().year(), month: (moment().month() + 1) });
    const [action, setAction] = useState(false)

    useEffect(() => {
        getEvents(currMonth)
    }, [])

    useEffect(() => {
        getEvents(currMonth)
    }, [action])

    const getEvents = (date) => {
        const year = date.year
        const month = date.month
        const startDate = moment(`${year}-${month}-01`, 'YYYY-MM-DD');

        const datesArray = [];

        while (startDate.month() + 1 === month) {
            datesArray.push(startDate.format('YYYY-MM-DD'));
            startDate.add(1, 'days');
        }

        var newAgenda = agendaItems
        var marked = marked
        datesArray.map((date) => {
            if (Object.keys(newAgenda).includes(date)) {

            } else {
                if (slots) {
                    const events = slots.filter((slot) => moment(slot.start).format("YYYY-MM-DD") === date)
                    if (events.length > 0) {
                        newAgenda = { ...newAgenda, [date]: [...events.map((slot) => { return { name: "Training Session", ...slot } })] }
                        marked = { ...marked, [date]: { marked: true } }
                    } else {
                        newAgenda = { ...newAgenda, [date]: [] }
                    }
                } else {
                    newAgenda = { ...newAgenda, [date]: [] }
                }
            }
        })

        setAgendaItems(newAgenda)
        setMarked(marked)
    };

    const renderDay = (day) => {
        if (day) {
            return <Text style={styles.customDay}>{day.getDay()}</Text>;
        }
        return <View style={styles.dayItem} />;
    };

    const renderItem = (session, isFirst) => {
        const fontSize = 16;
        const color = 'black';

        return (
            // <TouchableOpacity
            //     style={[styles.item, { height: session.height }]}
            //     onPress={() => alert(session.additionalInfo)}
            // >
            //     <Text style={{ fontSize, color, textDecorationLine: moment(session.start).isBefore(moment()) ? "line-through" : undefined }}>{session.name}</Text>
            //     <Text style={{ fontSize: 12, color, textDecorationLine: moment(session.start).isBefore(moment()) ? "line-through" : undefined }}>{moment(session.start).format("hh:mm a")} {"->"} {moment(session.end).format("hh:mm a")}</Text>
            // </TouchableOpacity>
            <View style={{width: "90%"}}>
                <SessionCard session={session} key={session._id}  navigation={navigation} action={action} setAction={setAction} />
            </View>
        );
    };

    const renderEmptyDate = () => {
        return (
            <View style={styles.emptyDate}>
                <Text>Nothing Today</Text>
            </View>
        );
    };

    const rowHasChanged = (r1, r2) => {
        return r1.name !== r2.name;
    };

    function timeToString(time) {
        const date = new Date(time);
        return date.toISOString().split('T')[0];
    }


    return (
        <>
            <AppBar navigation={navigation} title={`${academy?.title} Agenda`} action={<>
                {trainee && <Appbar.Action icon="qrcode" onPress={showModal} />}
                {user?.type === "Super" && <Appbar.Action icon="calendar-plus" onPress={() => navigation.navigate("AddSessions", { academy, coaches })} />}
            </>} />
            <View style={globalStyles.container}>
                {trainee && (
                    <View>
                        <Portal>
                            <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                <Text style={{ marginBottom: 15, fontSize: "20pt" }}>Show QR Code to attend session</Text>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={user?._id}
                                />
                            </Modal>
                        </Portal>
                    </View>
                )}
                <Agenda
                    style={{ width: "100%" }}
                    items={agendaItems}
                    markedDates={marked}
                    selected={moment().format("YYYY-MM-DD")}
                    loadItemsForMonth={(month) => {
                        if (month.month !== currMonth.month || month.year !== currMonth.year) {
                            setCurrMonth(month)
                            getEvents(month)
                        }
                    }}
                    renderItem={renderItem}
                    renderEmptyDate={renderEmptyDate}
                    rowHasChanged={rowHasChanged}
                    showClosingKnob={true}
                />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    item: {
        backgroundColor: 'white',
        flex: 1,
        borderRadius: 5,
        padding: 10,
        marginRight: 10,
        marginTop: 17
    },
    emptyDate: {
        height: 15,
        flex: 1,
        paddingTop: 30
    },
    customDay: {
        margin: 10,
        fontSize: 24,
        color: 'green'
    },
    dayItem: {
        marginLeft: 34
    }
});

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    trainee: state?.auth?.user?.trainee,
    academy: state?.records?.academy,
    slots: state?.records?.slots
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AcademyAgenda);