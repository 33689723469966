import * as React from 'react';
import { Avatar, Button, Card, IconButton } from 'react-native-paper';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteTeam } from '../store/actions/adminActions';
import { getTournament } from '../store/actions/userActions';
import { useNavigation } from '@react-navigation/native';

export const TeamCard = ({ team, navigation, token, deleteTeam, getTournament }) => {
    const role = token?.split(" ")[0]
    const handleDelete = () => {
        deleteTeam({teamId: team._id})
        getTournament({tournamentId: team.tournament})
    }

    return (
        <Card style={defaultStyles.card} onPress={() => navigation.navigate("Team", { teamId: team._id })}>
            <Card.Title
                title={team.name}
                subtitle={`Points ${team.points}`}
                left={(props) => <Avatar.Icon style={{backgroundColor: team.color}} {...props} icon="shield-star" />}
            />
            {role && role === "Super" &&
                <Card.Actions>
                    <Button onPress={handleDelete} icon="delete">Delete</Button>
                </Card.Actions>
            }
        </Card>
    )
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token
});

const mapDispatchToProps = {
    deleteTeam,
    getTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCard);