import React, { useState } from "react";
import { View, ScrollView, Text } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import { addMatch, editMatchEvent } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import { getPlayers, getSport } from "../../app/store/actions/userActions";
import DropDown from "react-native-paper-dropdown";
import AppBar from "../../AppBar";
import { useEffect } from "react";

const EditMatchEvent = ({ editMatchEvent, getPlayers, isLoading, isWaiting, players, route, getSport, sport }) => {

    const navigation = useNavigation();
    const { match, event } = route.params;
    const [update, setUpdate] = useState({
        eventId: event._id,
        player: event.player,
        team: event.team,
        time: event.time,
        type: event.type,
        cautionType: event.cautionType
    })
    const { team, player, eventId } = update

    const [showDropDown3, setShowDropDown3] = useState(false);

    useEffect(() => {
        getSport({ sportId: match.tournament.sport })
        getPlayers({ teamId: team, matchId: match._id })
    }, [])

    const handleEditMatchEvent = () => {
        const update = {
            eventId,
            player
        }
        console.log(update)
        editMatchEvent({ update }, navigation)
    };

    if (isLoading || isWaiting) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title="Update Match" />
            <View style={{ padding: 30 }}>
                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Player"}
                    mode={"outlined"}
                    visible={showDropDown3}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown3(true)}
                    onDismiss={() => setShowDropDown3(false)}
                    value={player}
                    setValue={(val) => setUpdate({...update, player: val})}
                    list={Array.isArray(players) && players?.map((player) => {
                        return (player.isBanned?.length === 0 && player.hasRed?.length === 0) && { label: `${player.player.name}${player.hasYellow[0] ? " (Yellow Card)" : ""}`, value: player.player._id }
                    }) || [{ label: "None", value: "None" }]}
                />
                <Button mode="contained" onPress={handleEditMatchEvent}>
                    Add Update
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.records?.isLoading,
    isWaiting: state?.wait?.isLoading,
    players: state?.records?.players,
    sport: state?.records?.sport,
});

const mapDispatchToProps = {
    getPlayers,
    editMatchEvent,
    getSport
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMatchEvent);