import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Divider, FAB, Menu, Modal, Portal, TextInput } from 'react-native-paper';
import AppBar from '../../../AppBar';
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { RefreshControl } from 'react-native';
import globalStyles from '../../../app/components/Styles';
import AcademyCard from '../../../app/components/AcademyCard';
import { getCoaches } from '../../../app/store/actions/userActions';
import CoachCard from '../../../app/components/CoachCard';

export const Coaches = ({ user, getCoaches, isLoading, coaches }) => {

    const navigation = useNavigation();
    const [action, setAction] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };


    useEffect(() => {
        getCoaches({ page: 1, limit: 100, name: "" })
    }, [action])

    const refresher = () => {
        getCoaches({ page: 1, limit: 100, name: "" })
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }} refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"ROAR Coaches"} action={<>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("CreateAcademy"); closeMenu() }} leadingIcon="plus-circle" title="Create Academy" />
                    <Menu.Item onPress={() => { navigation.navigate("CreateCoach"); closeMenu() }} leadingIcon="plus-circle" title="Create Coach" />
                    <Menu.Item onPress={() => { navigation.navigate("Coaches"); closeMenu() }} leadingIcon="whistle" title="View Coaches" />
                    <Menu.Item onPress={() => { navigation.navigate("SuperPayments"); closeMenu() }} leadingIcon="receipt" title="View Payments" />
                    <Menu.Item onPress={() => { navigation.navigate("Payouts"); closeMenu() }} leadingIcon="cash-fast" title="View Payouts" />
                </Menu>
            </>} />
            <View style={{ ...globalStyles.container, marginTop: 5, paddingHorizontal: 20 }}>
                {isLoading && <ActivityIndicator />}
                {coaches?.length > 0 && coaches.map((coach) => { return <CoachCard action={action} setAction={setAction} coach={coach} navigation={navigation} key={coach._id} /> })}
            </View>
        </ScrollView>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    coaches: state?.records?.coaches?.docs
});

const mapDispatchToProps = { getCoaches };

export default connect(mapStateToProps, mapDispatchToProps)(Coaches);