import { StyleSheet } from 'react-native';
import { DefaultTheme } from 'react-native-paper';

const globalStyles = StyleSheet.create({
    text: {
        color: '#000000',
        fontFamily: 'Open Sans',
        fontSize: 16,
    },
    container: {
        flex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        height: "100%",
        width: "100%"
    },
    input: {
        marginBottom: 6,
        width: "80%"
    },
    button: {
        marginVertical: 10,
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 4,
        backgroundColor: DefaultTheme.colors.primary,
    },
    buttonText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    card: {
        width: "100%",
        padding: 3,
        marginVertical: 5,
    },
    table: {
        width: '100%',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        marginBottom: 5,
        borderColor: '#000000',
    },
    header: {
        backgroundColor: '#f2f2f2'
    },
    headerText: {
        fontWeight: 'bold'
    }
});

export default globalStyles;
