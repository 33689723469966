import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { AcademyHome, Login, SingleAcademy  } from "../Pages";
import { NavigationContainer } from "@react-navigation/native";
import { TraineeReservations, TraineePayments, AcademyAgenda, TraineeAttendance, CoachAgenda, CoachAttendance, Enroll, Reserve, AdminScanner, SuperPayments, CreateAcademy, CreateCoach, AddSessions, EditAcademy, EditSession, Payouts, Coaches, EditCoach } from "../Pages/AcademyPages";


const Stack = createStackNavigator();

export default function AcademyRouter({ }) {
    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="AcademyHome">
                <Stack.Screen name="AcademyHome" options={{ headerShown: false }} component={AcademyHome} />
                <Stack.Screen name="Scanner" options={{ headerShown: false }} component={AdminScanner} />
                <Stack.Screen name="Login" options={{ headerShown: false }} component={Login} />
                <Stack.Screen name="Enroll" options={{ headerShown: false }} component={Enroll} />
                <Stack.Screen name="Reserve" options={{ headerShown: false }} component={Reserve} />
                <Stack.Screen name="Academy" options={{ headerShown: false }} component={SingleAcademy} />
                <Stack.Screen name="Agenda" options={{ headerShown: false }} component={AcademyAgenda} />
                <Stack.Screen name="TraineeReservations" options={{ headerShown: false }} component={TraineeReservations} />
                <Stack.Screen name="TraineePayments" options={{ headerShown: false }} component={TraineePayments} />
                <Stack.Screen name="TraineeAttendance" options={{ headerShown: false }} component={TraineeAttendance} />
                <Stack.Screen name="CoachAgenda" options={{ headerShown: false }} component={CoachAgenda} />
                <Stack.Screen name="CoachAttendance" options={{ headerShown: false }} component={CoachAttendance} />
                <Stack.Screen name="SuperPayments" options={{ headerShown: false }} component={SuperPayments} />
                <Stack.Screen name="CreateAcademy" options={{ headerShown: false }} component={CreateAcademy} />
                <Stack.Screen name="CreateCoach" options={{ headerShown: false }} component={CreateCoach} />
                <Stack.Screen name="AddSessions" options={{ headerShown: false }} component={AddSessions} />
                <Stack.Screen name="EditAcademy" options={{ headerShown: false }} component={EditAcademy} />
                <Stack.Screen name="EditSession" options={{ headerShown: false }} component={EditSession} />
                <Stack.Screen name="Payouts" options={{ headerShown: false }} component={Payouts} />
                <Stack.Screen name="Coaches" options={{ headerShown: false }} component={Coaches} />
                <Stack.Screen name="EditCoach" options={{ headerShown: false }} component={EditCoach} />

            </Stack.Navigator>
        </NavigationContainer>
    );
}
