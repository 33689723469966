import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, StyleSheet } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { editMatch } from "../../app/store/actions/adminActions";
import { useNavigation } from "@react-navigation/native";
import { getSport } from "../../app/store/actions/userActions";
import AppBar from "../../AppBar";

const EndorStartMatch = ({ editMatch, getSport, isLoading, sport, route }) => {

    const navigation = useNavigation();
    const { match } = route.params;

    const [teamA, setTeamA] = useState(0);
    const [teamB, setTeamB] = useState(0);

    const [finalScoreA, setFinalScoreA] = useState(match.scoreA);
    const [finalScoreB, setFinalScoreB] = useState(match.scoreB);

    useEffect(() => {
        getSport({ sportId: match.tournament.sport })
    }, [])

    const handleEditMacth = () => {
        const edits = match.started ? {
            ended: true,
            addedA: finalScoreA - match.scoreA, 
            addedB: finalScoreB - match.scoreB 
        } : {
            started: true
        }
        const points = match.started ? {
            teamA,
            teamB
        } : undefined

        editMatch({ edits, points, matchId: match._id}, navigation)
    };

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title={match.started ? "End Match" : "Start Match"} />
            {match.started ? (
                <View style={{ padding: 30 }}>
                    {sport?.selfDefinePoints &&
                        <>
                            <TextInput
                                mode="outlined"
                                style={{ marginVertical: 4 }}
                                label={`${match.teamA.name} Points`}
                                value={teamA}
                                keyboardType="numeric"
                                onChangeText={(text) => setTeamA(text)}
                            />
                            <TextInput
                                mode="outlined"
                                style={{ marginVertical: 4 }}
                                label={`${match.teamB.name} Points`}
                                value={teamB}
                                keyboardType="numeric"
                                onChangeText={(text) => setTeamB(text)}
                            />
                        </>}
                    <View style={{alignItems: "center"}}>
                        <View style={{ width: "100%", ...grid.container }}>
                            <View style={grid.leftColumn}>
                                <Text style={grid.textStyle}>{match?.teamA?.name}</Text>
                            </View>
                            <View style={grid.centerColumn}>
                                <Text style={{ ...grid.textStyle }}>-</Text>
                            </View>
                            <View style={grid.rightColumn}>
                                <Text style={grid.textStyle}>{match?.teamB?.name}</Text>
                            </View>
                        </View>
                        <View style={{ width: "100%", ...grid.container }}>
                            <View style={grid.leftColumn}>
                                <TextInput
                                    mode="outlined"
                                    style={{ marginVertical: 4 }}
                                    label={`Goals`}
                                    value={`${finalScoreA}`}
                                    keyboardType="numeric"
                                    onChangeText={(text) => setFinalScoreA(text)}
                                />
                            </View>
                            <View style={grid.centerColumn}>
                                <Text style={{ ...grid.textStyle }}>-</Text>
                            </View>
                            <View style={grid.rightColumn}>
                                <TextInput
                                    mode="outlined"
                                    style={{ marginVertical: 4 }}
                                    label={`Goals`}
                                    value={`${finalScoreB}`}
                                    keyboardType="numeric"
                                    onChangeText={(text) => setFinalScoreB(text)}
                                />
                            </View>
                        </View>
                    </View>

                    <Button mode="contained" onPress={handleEditMacth}>
                        End Match
                    </Button>
                </View>
            ) : (
                <View style={{ padding: 30 }}>
                    <Button mode="contained" onPress={handleEditMacth}>
                        Start Match
                    </Button>
                </View>
            )}
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.records?.isLoading,
    sport: state?.records?.sport,
});

const mapDispatchToProps = {
    getSport,
    editMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(EndorStartMatch);

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300,
        padding: "3%",
        marginBottom: 5
    },
    textStyle: {
        fontSize: 25
    },
    leftColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-start",
        flexDirection: "row",
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-end",
        flexDirection: "row",
    },
    item: {
        margin: 0,
    },
    badge: {
        position: 'absolute',
        top: -2,
        right: -6,
    },
});