import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl, Dimensions } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, DataTable, Divider, Drawer, Menu } from 'react-native-paper';
import { TabScreen, Tabs } from 'react-native-paper-tabs';
import { getReservations } from '../../../app/store/actions/userActions';
import AppBar from '../../../AppBar';
import globalStyles from '../../../app/components/Styles';
import moment from 'moment';
import DropDown from 'react-native-paper-dropdown';

export const TraineeReservations = ({ token, user, isLoading, reservations, getReservations }) => {

    const navigation = useNavigation();

    const [filters, setFilters] = useState({
        page: 1,
        limit: 500,
        valid: undefined,
        paymentStatus: undefined
    })

    const handleChangeFilter = (value) => {
        setFilters({
            ...filters,
            valid: value
        })
    }

    const [filter, setFilter] = React.useState(false)
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const openFilter = () => {
        setFilter(true)
    }

    const closeFilter = () => {
        setFilter(false)
    }

    const [showDropDown, setShowDropDown] = useState(false)
    const openDropDown = () => {
        setShowDropDown(true)
    }

    const closeDropDown = () => {
        setShowDropDown(false)
    }

    const handleClearFilter = () => {
        setFilters({
            page: 1,
            limit: 500,
            valid: undefined,
            paymentStatus: undefined
        })
    }

    const refresher = async () => {
        getReservations(filters)
    }

    useEffect(() => {
        refresher()
    }, [user])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"My Reservations"} action={<>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("TraineeReservations"); closeMenu() }} leadingIcon="bookmark-check" title="My Reservations" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineePayments"); closeMenu() }} leadingIcon="wallet" title="My Balance" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineeAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                </Menu>
                <Menu
                    visible={filter}
                    onDismiss={closeFilter}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="filter-outline" id="filter-menu" onPress={openFilter} />}>
                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Validity"}
                        mode={"outlined"}
                        visible={showDropDown}
                        dropDownStyle={{ marginVertical: 4, marginHorizontal: 5 }}
                        showDropDown={openDropDown}
                        onDismiss={closeDropDown}
                        value={filters.valid}
                        setValue={handleChangeFilter}
                        list={[
                            { label: "Valid Only", value: true },
                            { label: "Any", value: undefined },
                        ]}
                    />
                    <Divider />
                    <Button onPress={refresher}>Apply Filters</Button>
                    <Button onPress={handleClearFilter}>Clear Filters</Button>
                </Menu>
            </>} />
            <View style={globalStyles.container}>
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 3 }}>Academy</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Remaining</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Valid Until</DataTable.Title>
                    </DataTable.Header>

                    {reservations?.map((reservation) => (
                        <DataTable.Row key={reservation._id}>
                            <DataTable.Cell style={{ flex: 3 }}><Text>{reservation.academy.title}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{reservation.remaining} Sessions</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{reservation.payment.status === "Accepted" ? moment(reservation.createdAt).format("DD/MM/YYYY") : "Invalid (Unpaid)"}</Text></DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    reservations: state?.records?.reservations?.docs,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getReservations
};

export default connect(mapStateToProps, mapDispatchToProps)(TraineeReservations);