import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { Profile, Login, Notifications, ResetPassword } from "../Pages";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

const Stack = createStackNavigator();

export default function ProfileRouter({ }) {

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Profile">
        <Stack.Screen name="Profile" options={{ headerShown: false }} component={Profile} />
        <Stack.Screen name="Login" options={{ headerShown: false }} component={Login} />
        <Stack.Screen name="Reset" options={{ headerShown: false }} component={ResetPassword} />
        <Stack.Screen name="Notifications" options={{ headerShown: false }} component={Notifications} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
