import * as React from 'react';
import { BottomNavigation, DefaultTheme, Text } from 'react-native-paper';
import { AcademyRouter, AdminRouter, MatchesRouter, OlympicsRouter, ProfileRouter, TournamentRouter } from './Routers';
import { connect } from 'react-redux';

const initialRoutes = [
    { key: 'matches', title: 'Matches', focusedIcon: 'soccer-field' },
    { key: 'tournaments', title: 'Tournaments', focusedIcon: 'trophy' },
    { key: 'olympics', title: 'Sports Days', focusedIcon: 'torch' },
    { key: 'academies', title: 'Academies', focusedIcon: 'traffic-cone' },
    { key: 'more', title: 'More', focusedIcon: 'dots-horizontal-circle-outline' },
]

const superRoutes = [
    { key: 'matches', title: 'Matches', focusedIcon: 'soccer-field' },
    { key: 'tournaments', title: 'Tournaments', focusedIcon: 'trophy' },
    { key: 'olympics', title: 'Sports Days', focusedIcon: 'torch' },
    { key: 'academies', title: 'Academies', focusedIcon: 'traffic-cone' },
    { key: 'admin', title: 'Admin Actions', focusedIcon: 'shield-crown-outline' },
    { key: 'more', title: 'More', focusedIcon: 'dots-horizontal-circle-outline' },
]

const userRoutes = [
    { key: 'matches', title: 'Matches', focusedIcon: 'soccer-field' },
    { key: 'tournaments', title: 'Tournaments', focusedIcon: 'trophy' },
    { key: 'olympics', title: 'Sports Days', focusedIcon: 'torch' },
    { key: 'academies', title: 'Academies', focusedIcon: 'traffic-cone' },
    { key: 'more', title: 'More', focusedIcon: 'dots-horizontal-circle-outline' },
]

const adminRoutes = [
    { key: 'matches', title: 'Matches', focusedIcon: 'soccer-field' },
    { key: 'tournaments', title: 'Tournaments', focusedIcon: 'trophy' },
    { key: 'olympics', title: 'Sports Days', focusedIcon: 'torch' },
    { key: 'more', title: 'More', focusedIcon: 'dots-horizontal-circle-outline' },
]

const coachRoutes = [
    { key: 'academies', title: 'Academies', focusedIcon: 'traffic-cone' },
    { key: 'attendance', title: 'Attendance', focusedIcon: 'soccer-field' },
    { key: 'schedule', title: 'Schedule', focusedIcon: 'trophy' },
    { key: 'more', title: 'More', focusedIcon: 'dots-horizontal-circle-outline' },
]

const RouterBottom = ({ user, token }) => {
    const role = token?.split(" ")[0]
    const [index, setIndex] = React.useState(0);
    const [routes, setRoutes] = React.useState(initialRoutes.map((el) => { return el }));

    React.useEffect(() => {
        if (role === "Super") {
            setIndex(0)
            setRoutes(superRoutes)
        } else if (role === "User") {
            setIndex(0)
            setRoutes(userRoutes)
        } else if (role === "Admin") {
            setIndex(0)
            setRoutes(adminRoutes)
        } else {
            setIndex(0)
            setRoutes(initialRoutes)
        }
    }, [user])

    const renderScene = BottomNavigation.SceneMap({
        more: ProfileRouter,
        matches: MatchesRouter,
        tournaments: TournamentRouter,
        olympics: OlympicsRouter,
        admin: AdminRouter,
        academies: AcademyRouter
    });

    return (
        <BottomNavigation
            theme={{
                ...DefaultTheme,
                colors: {
                    ...DefaultTheme.colors,
                    primary: '#000B26',
                    secondaryContainer: '#ab8f5275',
                    onSecondaryContainer: '#ffffff',
                    onSurfaceVariant: '#dddddd',
                    onSurface: '#ffffff',
                    elevation: {
                        ...DefaultTheme.colors.elevation,
                        level2: "#000B26",
                    }
                }
            }}
            navigationState={{ index, routes }}
            onIndexChange={setIndex}
            renderScene={renderScene}
        />
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterBottom);