import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, IconButton, Menu, Modal, Portal, TextInput } from 'react-native-paper';
import AppBar from '../../../AppBar'
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { RefreshControl } from 'react-native';
import globalStyles from '../../../app/components/Styles';
import { Agenda, Calendar, AgendaEntry } from 'react-native-calendars';
import moment from 'moment';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { getCoachAgenda } from '../../../app/store/actions/userActions';

export const CoachAgenda = ({ user, slots, isLoading, getCoachAgenda }) => {

    const navigation = useNavigation();
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
    const [marked, setMarked] = useState({});
    const [agendaItems, setAgendaItems] = useState({});
    const [currMonth, setCurrMonth] = useState({ year: moment().year(), month: (moment().month() + 1) });
    const [menuVisible, setMenuVisible] = React.useState(false);
    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    useEffect(() => {
        getCoachAgenda({ page: 1, limit: 500 })
    }, [user])

    useEffect(() => {
        if (!Array.isArray(slots)) {
            getEvents(currMonth)
        }
    }, [currMonth, slots, isLoading])


    const getEvents = (date) => {
        const year = date.year
        const month = date.month
        const startDate = moment(`${year}-${month}-01`, 'YYYY-MM-DD');

        const datesArray = [];

        while (startDate.month() + 1 === month) {
            datesArray.push(startDate.format('YYYY-MM-DD'));
            startDate.add(1, 'days');
        }

        var newAgenda = agendaItems
        var marked = marked
        datesArray.map((date) => {
            if (Object.keys(newAgenda).includes(date)) {

            } else {
                if (slots) {
                    if (slots[date]?.length > 0) {
                        newAgenda = { ...newAgenda, [date]: slots[date].map((slot) => { return { ...slot, name: "Training Session" } }) }
                        marked = { ...marked, [date]: { marked: true } }
                    } else {
                        newAgenda = { ...newAgenda, [date]: [] }
                    }
                } else {
                    newAgenda = { ...newAgenda, [date]: [] }
                }
            }
        })

        setAgendaItems(newAgenda)
        setMarked(marked)
    };

    const renderDay = (day) => {
        if (day) {
            return <Text style={styles.customDay}>{day.getDay()}</Text>;
        }
        return <View style={styles.dayItem} />;
    };

    const renderItem = (session, isFirst) => {
        const fontSize = 16;
        const color = 'black';

        return (
            <TouchableOpacity
                style={[styles.item, { height: session.height }]}
                onPress={() => alert(session.additionalInfo)}
            >
                <Text style={{ fontSize, color, textDecorationLine: moment(session.start).isBefore(moment()) ? "line-through" : undefined }}>{session.name}</Text>
                <Text style={{ fontSize: 12, color, textDecorationLine: moment(session.start).isBefore(moment()) ? "line-through" : undefined }}>{moment(session.start).subtract(1, "hour").format("hh:mm a")} {"->"} {moment(session.end).subtract(1, "hour").format("hh:mm a")}</Text>
            </TouchableOpacity>
        );
    };

    const renderEmptyDate = () => {
        return (
            <View style={styles.emptyDate}>
                <Text>Nothing Today</Text>
            </View>
        );
    };

    const rowHasChanged = (r1, r2) => {
        return r1.name !== r2.name;
    };

    function timeToString(time) {
        const date = new Date(time);
        return date.toISOString().split('T')[0];
    }

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <>
            <AppBar navigation={navigation} title={`My Agenda`} action={<>
                <IconButton icon="refresh" onPress={() => getCoachAgenda({ page: 1, limit: 500 })} />
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("CoachProfile"); closeMenu() }} leadingIcon="account" disabled title="My Profile" />
                    <Menu.Item onPress={() => { navigation.navigate("CoachAgenda"); closeMenu() }} leadingIcon="calendar" title="My Agenda" />
                    <Menu.Item onPress={() => { navigation.navigate("CoachAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                </Menu>
            </>} />
            <View style={globalStyles.container}>
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                        <Text style={{ marginBottom: 15, fontSize: 20 }}>Show QR Code to attend session</Text>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={user?._id}
                        />
                    </Modal>
                </Portal>
                <Agenda
                    style={{ width: "100%" }}
                    items={agendaItems}
                    markedDates={marked}
                    selected={moment().format("YYYY-MM-DD")}
                    loadItemsForMonth={(month) => {
                        if (month.month !== currMonth.month || month.year !== currMonth.year) {
                            setCurrMonth(month)
                            getEvents(month)
                        }
                    }}
                    renderItem={renderItem}
                    renderEmptyDate={renderEmptyDate}
                    rowHasChanged={rowHasChanged}
                    showClosingKnob={true}
                />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    item: {
        backgroundColor: 'white',
        flex: 1,
        borderRadius: 5,
        padding: 10,
        marginRight: 10,
        marginTop: 17
    },
    emptyDate: {
        height: 15,
        flex: 1,
        paddingTop: 30
    },
    customDay: {
        margin: 10,
        fontSize: 24,
        color: 'green'
    },
    dayItem: {
        marginLeft: 34
    }
});

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    coach: state?.auth?.user?.coach,
    slots: state?.records?.slots,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getCoachAgenda };

export default connect(mapStateToProps, mapDispatchToProps)(CoachAgenda);