import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl, Dimensions, StyleSheet } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Card, DataTable, Divider, Drawer, IconButton, Menu, Modal, Portal, TextInput } from 'react-native-paper';
import { TabScreen, Tabs } from 'react-native-paper-tabs';
import { superPayments, acceptPayment, sendPaymentReminder, getPaymentsStatement } from '../../../app/store/actions/superActions';
import AppBar from '../../../AppBar';
import globalStyles from '../../../app/components/Styles';
import moment from 'moment';
import AlertDialog from '../../../app/components/AlertDialog';
import { getAcademies } from '../../../app/store/actions/userActions';
import DropDown from 'react-native-paper-dropdown';
import { CameraView, useCameraPermissions } from 'expo-camera';
import QrReader from "modern-react-qr-reader"

export const SuperPayments = ({ token, user, isLoading, payments, superPayments, acceptPayment, getAcademies, academies, sendPaymentReminder, getPaymentsStatement }) => {

    const navigation = useNavigation();
    const screenWidth = Dimensions.get('screen').width;
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = { backgroundColor: 'white', width: (screenWidth - 50), height: (screenWidth - 50), margin: 30, justifyContent: "center", alignItems: "center", borderRadius: 10 };

    const [filters, setFilters] = useState({
        page: 1,
        limit: 500,
        userId: undefined,
        valid: undefined,
        academyId: undefined,
        userName: undefined,
        ipusername: undefined
    })

    const [tempFilters, setTempFilters] = useState({
        valid: undefined,
        academyId: undefined,
        userName: undefined,
        ipusername: undefined
    })

    const [acceptAlert, setAcceptAlert] = useState({
        visible: false,
        payment: undefined
    })

    const handleOpenAcceptAlert = (payment) => {
        setAcceptAlert({
            visible: true,
            payment
        })
    }

    const handleAcceptPayment = () => {
        acceptPayment({
            paymentId: acceptAlert.payment.payment._id
        })
        handleCloseAlert()
        refresher()
    }

    const handleCloseAlert = () => {
        setAcceptAlert({
            visible: false,
            paymentId: undefined
        })
    }

    const handleChangeFilter = (val, name) => {
        setTempFilters({ ...tempFilters, [name]: val })
    }

    const [filter, setFilter] = React.useState(false)
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const openFilter = () => {
        setFilter(true)
    }

    const closeFilter = () => {
        setFilter(false)
    }

    const [showDropDown, setShowDropDown] = useState(false)
    const openDropDown = () => {
        setShowDropDown(true)
    }

    const closeDropDown = () => {
        setShowDropDown(false)
    }

    const [showDropDown1, setShowDropDown1] = useState(false)
    const openDropDown1 = () => {
        setShowDropDown1(true)
    }

    const closeDropDown1 = () => {
        setShowDropDown1(false)
    }

    const handleApplyFilter = () => {
        setFilters({
            ...filters,
            ...tempFilters
        })
    }

    const handleClearFilter = () => {
        setFilters({
            page: 1,
            limit: 500,
            valid: undefined,
            userId: undefined,
            accademyId: undefined
        })
    }

    const refresher = async () => {
        superPayments(filters)
    }

    useEffect(() => {
        refresher()
    }, [user, filters])

    useEffect(() => {
        getAcademies({ page: 1, limit: 500, title: "" })
    }, [])

    const [scanned, setScanned] = useState(false);
    const [permission, requestPermission] = useCameraPermissions();

    const handleBarCodeScanned = (data) => {
        if (data) {
            setFilters({ ...filters, userId: data });
            hideModal();
        }
    };

    const handleDownloadStatement = () => {
        getPaymentsStatement({ valid: filters.valid, academyId: filters.academyId })
    };


    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"Academies Payments"} action={<>
                <Appbar.Action icon="qrcode-scan" onPress={showModal} />
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("CreateAcademy"); closeMenu() }} leadingIcon="plus-circle" title="Create Academy" />
                    <Menu.Item onPress={() => { navigation.navigate("CreateCoach"); closeMenu() }} leadingIcon="plus-circle" title="Create Coach" />
                    <Menu.Item onPress={() => { navigation.navigate("SuperPayments"); closeMenu() }} leadingIcon="receipt" title="View Payments" />
                    <Menu.Item onPress={() => { navigation.navigate("Payouts"); closeMenu() }} leadingIcon="cash-fast" title="View Payouts" />
                    <Divider />
                    <Menu.Item onPress={() => { sendPaymentReminder(); closeMenu() }} leadingIcon="bell-ring" title="Send Reminders" />
                </Menu>
                <Menu
                    visible={filter}
                    onDismiss={closeFilter}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="filter-outline" id="filter-menu" onPress={openFilter} />}>

                    <TextInput
                        mode="outlined"
                        label="User Name"
                        value={tempFilters.userName}
                        onChangeText={text => handleChangeFilter(text, "userName")}
                    />

                    <TextInput
                        mode="outlined"
                        label="InstaPay Username"
                        value={tempFilters.ipusername}
                        onChangeText={text => handleChangeFilter(text, "ipusername")}
                    />

                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Academy"}
                        mode={"outlined"}
                        visible={showDropDown1}
                        dropDownStyle={{ marginVertical: 4, marginHorizontal: 5 }}
                        showDropDown={openDropDown1}
                        onDismiss={closeDropDown1}
                        value={tempFilters.academyId}
                        setValue={(val) => handleChangeFilter(val, "academyId")}
                        list={academies?.map((academy) => {
                            return {
                                label: academy.title, value: academy._id
                            }
                        })}
                    />

                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Validity"}
                        mode={"outlined"}
                        visible={showDropDown}
                        dropDownStyle={{ marginVertical: 4, marginHorizontal: 5 }}
                        showDropDown={openDropDown}
                        onDismiss={closeDropDown}
                        value={tempFilters.valid}
                        setValue={(val) => handleChangeFilter(val, "valid")}
                        list={[
                            { label: "Valid", value: true },
                            { label: "Invalid", value: false },
                            { label: "Any", value: undefined }
                        ]}
                    />
                    <Divider />
                    <Button onPress={handleApplyFilter}>Apply Filters</Button>
                    <Button onPress={handleClearFilter}>Clear Filters</Button>
                </Menu>
            </>} />
            <View style={globalStyles.container}>
                <View>
                    <Portal>
                        <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                            <View style={{ ...globalStyles.container, width: "100%" }}>
                                <Text style={{ fontWeight: "bold", fontSize: 15, marginBottom: 10 }}>Scan User QR-Code to get their payments</Text>
                                {permission?.granted && <View style={styles.container}>
                                    <QrReader
                                        onScan={(data) => scanned ? null : handleBarCodeScanned(data)}
                                        style={{
                                            height: 240,
                                            width: "100%",
                                        }}
                                    />
                                </View>}
                                {!permission?.granted && (
                                    <View>
                                        <Text style={{ textAlign: 'center' }}>We need your permission to show the camera</Text>
                                        <Button mode="contained" style={{ marginTop: 10 }} onPress={requestPermission}> Grant Permission</Button>
                                    </View>
                                )}
                            </View>
                        </Modal>
                    </Portal>
                </View>
                <Button mode="contained" style={{ marginVertical: 10 }} onPress={() => handleDownloadStatement()}>Download Statement</Button>
                <Text style={{ fontWeight: "bold", fontSize: 15, marginVertical: 10 }}>Press on payment to accept</Text>
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 2 }}>Academy</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>User</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Amount</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Status</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Remaining</DataTable.Title>
                    </DataTable.Header>

                    {payments?.map((payment) => (
                        <DataTable.Row onPress={() => payment.payment.status === "Accepted" ? undefined : handleOpenAcceptAlert(payment)} key={payment.payment._id}>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.academy.title}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.user.name}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.payment.amount}EGP</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.payment.status}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.remaining} Sess.</Text></DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
                {filters.userId && <Button style={{ marginVertical: 10 }} mode="contained" onPress={handleClearFilter}>Clear User Filter</Button>}
                <AlertDialog visible={acceptAlert.visible} message={`Are you sure you want to accept ${acceptAlert.payment?.user.name}'s payment of ${acceptAlert.payment?.payment.amount}EGP`} okText={"ACCEPT"} handleAccept={handleAcceptPayment} handleClose={handleCloseAlert} />
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 150,
    }
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    payments: state?.records?.reservations?.docs,
    academies: state?.records?.academies?.docs,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    superPayments, acceptPayment, getAcademies, sendPaymentReminder, getPaymentsStatement
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperPayments);