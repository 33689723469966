import React, { useEffect, useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text, ActivityIndicator, Menu, Appbar } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../AppBar";
import DropDown from "react-native-paper-dropdown";
import { createAdmin } from "../../app/store/actions/superActions";

const CreateAdmin = ({ createAdmin, isLoading }) => {

    const navigation = useNavigation();
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const [userData, setUserData] = useState({
        name: "",
        username: "",
        email: "",
        mobile: ""
    });

    const handleCreateAdmin = () => {
        createAdmin(userData, navigation)
    };


    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View>
            <AppBar navigation={navigation} title={"Create Matches Admin"}/>
            <ScrollView style={{ padding: 30 }} contentContainerStyle={{ justifyContent: "center" }}>
                <TextInput
                    mode="outlined"
                    label="Name*"
                    value={userData.name}
                    onChangeText={(text) => setUserData({ ...userData, name: text })}
                    style={{ marginVertical: 4 }}
                />
                <TextInput
                    mode="outlined"
                    label="Username*"
                    value={userData.username}
                    onChangeText={(text) => setUserData({ ...userData, username: text })}
                    style={{ marginVertical: 4 }}
                />
                <TextInput
                    mode="outlined"
                    label="Email*"
                    inputMode="email"
                    value={userData.email}
                    onChangeText={(text) => setUserData({ ...userData, email: text })}
                    style={{ marginVertical: 4 }}
                />
                <TextInput
                    mode="outlined"
                    label="Mobile"
                    inputMode="numeric"
                    value={userData.mobile}
                    onChangeText={(text) => setUserData({ ...userData, mobile: text })}
                    style={{ marginVertical: 4 }}
                />
                <Button mode="contained" style={{ marginVertical: 4 }} onPress={handleCreateAdmin}>
                    Create Admin
                </Button>
            </ScrollView>
        </View>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = {
    createAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);