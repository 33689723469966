import React, { useState } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { TextInput, Button, Snackbar, Chip, Divider, DataTable, IconButton } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import Papa from 'papaparse';
import { addTeam, editTeam } from '../../app/store/actions/adminActions';
import { connect } from 'react-redux';
import globalStyles from '../../app/components/Styles';
import { useNavigation } from '@react-navigation/native';
import AppBar from '../../AppBar';
import ColorPicker from 'react-native-wheel-color-picker';

const EditTeam = ({ editTeam, route }) => {

    const { team, tournament } = route.params;
    const [name, setName] = useState(team.name);
    const [color, setColor] = useState(team.color)
    const [group, setGroup] = useState(team.group);
    const navigation = useNavigation();

    const onSelectColor = (item) => {
        setColor(item)
    };

    const handleEditTeam = async () => {
        try {
            const edits = {
                name,
                group,
                color
            }
            console.log(edits);

            editTeam({ edits, teamId: team._id }, navigation);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title={`Edit ${name}`} />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Team name"
                    value={name}
                    onChangeText={setName}
                    required
                />
                {tournament.type === "Group-Knockout" &&
                    <TextInput
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Team Group"
                        value={group}
                        onChangeText={setGroup}
                        required
                    />}
                <View style={{ justifyContent: "center", alignItems: "center" }}>
                    <ColorPicker color={color} onColorChangeComplete={onSelectColor} thumbSize={15} sliderHidden={true} swatches={false}/>
                </View>
                <Button style={{ marginBottom: 8 }} mode="contained" onPress={handleEditTeam}>
                    Edit team
                </Button>
            </View >
        </ScrollView >
    );
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    editTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
