import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Button, DataTable, DefaultTheme, Drawer } from 'react-native-paper';
import { getOlympiad } from '../../app/store/actions/userActions';
import globalStyles from '../../app/components/Styles';
import AppBar from '../../AppBar';
import TeamCard from '../../app/components/TeamCard';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import MatchCard from '../../app/components/MatchCard';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import TournamentCard from '../../app/components/TournamentCard';
import { AntDesign } from "@expo/vector-icons"

export const Olympiad = ({ token, getOlympiad, olympiad, isLoading, tournaments, teams, matches, table, route, connectWebSocket, disconnectWebSocket, user }) => {

    const { olympiadId } = route.params;
    const navigation = useNavigation();
    const role = token?.split(" ")[0];
    const isFocused = useIsFocused();

    const refresher = async () => {
        getOlympiad({ olympiadId })
        const device = await Device.getDeviceTypeAsync()
        if (device !== Device.DeviceType.DESKTOP) {
            connectWebSocket()
        }
    }

    useEffect(() => {
        if (isFocused) {
            refresher()
        } else {
            disconnectWebSocket()
        }
    }, [isFocused, olympiadId])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <>
            <AppBar navigation={navigation} title={olympiad?.name} />
            <TabsProvider
                defaultIndex={0}
            >
                <Tabs theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff",
                        onSurface: '#ffffff',
                    },
                }}>
                    <TabScreen label='Table'>
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, marginTop: 50, paddingHorizontal: 30 }}>
                                <DataTable style={globalStyles.table}>
                                    <DataTable.Header style={globalStyles.header}>
                                        <DataTable.Title style={{ flex: 4 }}>Name</DataTable.Title>
                                        <DataTable.Title style={{ flex: 2 }} numeric>PTS</DataTable.Title>
                                    </DataTable.Header>

                                    {table && Object.entries(table).map(([team, score], idx) => (
                                        <DataTable.Row key={team._id}>
                                            <DataTable.Cell style={{ flex: 4 }} >{team}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 2 }} numeric>{score}</DataTable.Cell>
                                        </DataTable.Row>
                                    ))}
                                </DataTable>
                                {role && role === "Super" &&
                                    <View>
                                        <Button
                                            mode="contained"
                                            onPress={() => navigation.navigate('CreateTeamOlympiad', { olympiad })}
                                        >
                                            Create Team
                                        </Button>
                                    </View>
                                }
                            </ View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Tournaments" >
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, marginTop: 50, paddingHorizontal: 30 }}>
                                {
                                    tournaments?.map((tournament, i) => {
                                        return <TournamentCard key={i} navigation={navigation} tournament={tournament} />
                                    })
                                }
                                {
                                    tournaments?.length === 0 && (
                                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                                            <AntDesign name="dropbox" size={100} color="black" />
                                            <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                                        </View>
                                    )
                                }
                            </View>
                        </ScrollView>
                    </TabScreen>
                </Tabs>
            </TabsProvider>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token,
    olympiad: state?.records?.olympiad,
    matches: state?.records?.matches,
    tournaments: state?.records?.tournaments,
    table: state?.records?.table,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getOlympiad,
    connectWebSocket,
    disconnectWebSocket
};

export default connect(mapStateToProps, mapDispatchToProps)(Olympiad);