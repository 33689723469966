import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Avatar, Button, DataTable, DefaultTheme, Divider, Drawer, List, Snackbar } from 'react-native-paper';
import { getTournament, followMatch, getTournamentPlayers } from '../../app/store/actions/userActions';
import globalStyles from '../../app/components/Styles';
import AppBar from '../../AppBar';
import TeamCard from '../../app/components/TeamCard';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import MatchCard from '../../app/components/MatchCard';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { AntDesign } from "@expo/vector-icons";
import { getSuggestedMatches } from '../../app/store/actions/adminActions';
import SuggestedMatchCard from '../../app/components/SuggestedMatchCard';
import KOCard from '../../app/components/KOCard';
import * as Clipboard from 'expo-clipboard';

export const Tournament = ({ token, user, getTournament, isLoading, tournament, teams, matches, suggested, groupedTeams, players, route, connectWebSocket, disconnectWebSocket, followMatch, getTournamentPlayers, getSuggestedMatches }) => {

    const { tournamentId } = route.params;
    const navigation = useNavigation();
    const role = token?.split(" ")[0];
    const isFocused = useIsFocused();
    const [goalsSort, setGoalsSort] = useState(null);
    const [assistsSort, setAssistsSort] = useState(null);
    const [yellowSort, setYellowSort] = useState(null);
    const [redSort, setRedSort] = useState(null);
    const [sortedPlayers, setSortedPlayers] = useState(null)
    const [currIdx, setCurrIdx] = useState(0)
    const [action, setAction] = useState(false)

    const handleTabChange = (idx) => {
        setCurrIdx(idx)
    }

    const sortPlayers = (target) => {
        switch (target) {
            case "Goals":
                if (goalsSort === "descending") {
                    setGoalsSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.goalCount - b.goalCount));
                } else {
                    setGoalsSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.goalCount - a.goalCount));
                }
                setAssistsSort(null);
                setYellowSort(null);
                setRedSort(null);
                break;
            case "Assists":
                if (assistsSort === "descending") {
                    setAssistsSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.assistCount - b.assistCount));
                } else {
                    setAssistsSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.assistCount - a.assistCount));
                }
                setGoalsSort(null);
                setYellowSort(null);
                setRedSort(null);
                break;
            case "Red":
                if (redSort === "descending") {
                    setRedSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.redCount - b.redCount));
                } else {
                    setRedSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.redCount - a.redCount));
                }
                setGoalsSort(null);
                setYellowSort(null);
                setAssistsSort(null);
                break;
            case "Yellow":
                if (yellowSort === "descending") {
                    setYellowSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.yellowCount - b.yellowCount));
                } else {
                    setYellowSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.yellowCount - a.yellowCount));
                }
                setGoalsSort(null);
                setRedSort(null);
                setAssistsSort(null);
                break;
            default:
                setAssistsSort(null);
                setYellowSort(null);
                setRedSort(null);
                setGoalsSort("descending")
                setSortedPlayers(players?.sort((a, b) => b.goalCount - a.goalCount));
                break;
        }
    }

    const refresher = async () => {
        getTournament({ tournamentId })
        const device = await Device.getDeviceTypeAsync()
        if (device !== Device.DeviceType.DESKTOP) {
            connectWebSocket()
        }
    }

    useEffect(() => {
        if (isFocused) {
            refresher()
            if (role === "Admin" || role === "Super") {
                getSuggestedMatches({ tournamentId })
            }
        } else {
            disconnectWebSocket()
        }
    }, [isFocused, tournamentId, action])

    const getTabs = () => {
        switch (tournament?.type) {
            case "Knockout": return (
                [<TabScreen label='Knockouts' >
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={{ ...globalStyles.container, padding: 30 }}>
                            {matches?.filter((match) => match.round === "Round of 64").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 64</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 64").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Round of 32").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 32</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 32").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Round of 16").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 16</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 16").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Quarter Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Quarter Finals</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Quarter Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Semi Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Semi Finals</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Semi Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Final</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                        </ View>
                    </ScrollView>
                </TabScreen>]
            )
            case "League": return (
                [<TabScreen label='Table'>
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={{ ...globalStyles.container, padding: 30 }}>
                            {groupedTeams && Object.keys(groupedTeams).map((group, i) => (
                                <DataTable style={globalStyles.table} key={i}>
                                    <DataTable.Header style={globalStyles.header}>
                                        <DataTable.Title style={{ flex: 2 }}>Name</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>PL</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>W</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>D</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>L</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>+/-</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>GD</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>PTS</DataTable.Title>
                                    </DataTable.Header>

                                    {groupedTeams[group]?.map((team, idx) => (
                                        <DataTable.Row key={team._id}>
                                            <DataTable.Cell style={{ flex: 2 }} >{team.name}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.played}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.won}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.draw}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.lost}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.gf}-{team.ga}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{parseInt(team.gf) - parseInt(team.ga)}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.points}</DataTable.Cell>
                                        </DataTable.Row>
                                    ))}
                                </DataTable>
                            ))}
                        </ View>
                    </ScrollView>
                </TabScreen>]
            )
            default: return (
                [<TabScreen label='Table'>
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={{ ...globalStyles.container, padding: 30 }}>
                            {groupedTeams && Object.keys(groupedTeams).map((group, i) => (
                                <DataTable style={globalStyles.table} key={i}>
                                    <DataTable.Header style={globalStyles.header}>
                                        <DataTable.Title style={{ flex: 2 }}>Name</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>PL</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>W</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>D</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>L</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>+/-</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>GD</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric>PTS</DataTable.Title>
                                    </DataTable.Header>

                                    {groupedTeams[group]?.map((team, idx) => (
                                        <DataTable.Row key={team._id}>
                                            <DataTable.Cell style={{ flex: 2 }} >{team.name}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.played}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.won}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.draw}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.lost}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.gf}-{team.ga}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{parseInt(team.gf) - parseInt(team.ga)}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{team.points}</DataTable.Cell>
                                        </DataTable.Row>
                                    ))}
                                </DataTable>
                            ))}
                        </ View>
                    </ScrollView>
                </TabScreen>,
                <TabScreen label='Knockouts' >
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={{ ...globalStyles.container, padding: 30 }}>
                            {matches?.filter((match) => match.round === "Round of 64").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 64</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 64").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Round of 32").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 32</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 32").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Round of 16").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Round of 16</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Round of 16").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Quarter Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Quarter Finals</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Quarter Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Semi Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Semi Finals</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Semi Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                            {matches?.filter((match) => match.round === "Final").length > 0 && <Text style={{ fontSize: 25, fontWeight: "bold", marginTop: 15 }}>Final</Text>}
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    matches?.filter((match) => match.round === "Final").map((match, i) => {
                                        return <KOCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </View>
                        </ View>
                    </ScrollView>
                </TabScreen>]
            )
        }
    }

    const [isSnackbarVisible, setSnackbarVisible] = useState(false);

    const handleCopyToClipboard = async () => {
        await Clipboard.setStringAsync(`Hey, check out this ROAR tournament: "${tournament?.name}" https://roar.integraegy.com/tournament/${tournamentId}`);
        setSnackbarVisible(true);
    };

    if (isLoading || !tournament) {
        return (
            <View style={{ ...globalStyles.container, padding: 30 }}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <>
            <AppBar navigation={navigation} title={tournament?.name} action={<>
                <Appbar.Action icon="share" onPress={handleCopyToClipboard} />
                {role === "User" && user.tournamentsFollowed.includes(tournament?._id) ?
                    <Appbar.Action icon="star" iconColor='gold' /> :
                    <Appbar.Action onPress={() => followMatch({ tournament: tournament?._id })} icon="star-outline" />
                }
            </>} />
            <TabsProvider defaultIndex={0}>
                <Tabs mode="scrollable" showLeadingSpace={false} defaultIndex={currIdx} onChangeIndex={handleTabChange} theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff",
                        onSurface: '#ffffff',
                    },
                }}>
                    <TabScreen label='Matches'>
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, padding: 30 }}>
                                {
                                    matches?.map((match, i) => {
                                        return <MatchCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                                {
                                    matches?.length === 0 && (
                                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                                            <AntDesign name="dropbox" size={100} color="black" />
                                            <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                                        </View>
                                    )
                                }
                                <Divider style={{ marginVertical: 15 }} />
                                {role && role === "Super" && <>
                                    <List.Section style={{ width: "100%" }} title="Suggested Matches">
                                        <List.Accordion
                                            style={{ width: "100%" }}
                                            title="Matches">
                                            {suggested && suggested.map((match, i) => {
                                                return (
                                                    <SuggestedMatchCard key={i} navigation={navigation} match={match} />
                                                )
                                            })}
                                        </List.Accordion>
                                    </List.Section>

                                    <View>
                                        <Button
                                            mode="contained"
                                            onPress={() => navigation.navigate('CreateMatch', { tournament })}
                                        >
                                            Create Other Match
                                        </Button>
                                    </View>
                                </>
                                }
                            </ View>
                        </ScrollView>
                    </TabScreen>
                    {tournament && getTabs().map((el) => { return el })}
                    <TabScreen label="Teams">
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, padding: 30 }}>
                                {
                                    teams?.map((team, i) => {
                                        return <TeamCard key={i} navigation={navigation} team={team} />
                                    })
                                }
                                {
                                    teams?.length === 0 && (
                                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                                            <AntDesign name="dropbox" size={100} color="black" />
                                            <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                                        </View>
                                    )
                                }
                                {role && role === "Super" &&
                                    <View>
                                        <Button
                                            mode="contained"
                                            onPress={() => navigation.navigate('CreateTeam', { tournament })}
                                        >
                                            Create Team
                                        </Button>
                                    </View>
                                }
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Stats" onPress={sortPlayers}>
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, padding: 30 }}>
                                <DataTable style={globalStyles.table}>
                                    <DataTable.Header style={globalStyles.header}>
                                        <DataTable.Title style={{ flex: 2 }}>Name</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }}>Team</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={goalsSort} onPress={() => sortPlayers("Goals")} >Goals</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={assistsSort} onPress={() => sortPlayers("Assists")}>Assists</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={yellowSort} onPress={() => sortPlayers("Yellow")} >Yellow</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={redSort} onPress={() => sortPlayers("Red")}>Red</DataTable.Title>
                                    </DataTable.Header>

                                    {sortedPlayers?.map((player) => (
                                        <DataTable.Row key={player._id}>
                                            <DataTable.Cell style={{ flex: 2 }} >{player.player.name}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} >{player.player.team.name}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.goalCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.assistCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.yellowCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.redCount}</DataTable.Cell>
                                        </DataTable.Row>
                                    ))}
                                </DataTable>
                            </View>
                        </ScrollView>
                    </TabScreen>
                </Tabs >
            </TabsProvider>
            <Snackbar
                visible={isSnackbarVisible}
                onDismiss={() => setSnackbarVisible(false)}
                duration={2000} // Adjust as needed
                action={{
                    label: 'OK',
                    onPress: () => setSnackbarVisible(false),
                }}
            >
                Tournament link copied to clipboard!
            </Snackbar>
        </>
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    tournament: state?.records?.tournament,
    matches: state?.records?.matches,
    teams: state?.records?.teams,
    groupedTeams: state?.records?.groupedTeams,
    players: state?.records?.players,
    suggested: state?.records?.suggestions,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getTournament,
    followMatch,
    getTournamentPlayers,
    connectWebSocket,
    disconnectWebSocket,
    getSuggestedMatches
};

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);