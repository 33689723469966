import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteMatch } from '../store/actions/adminActions';
import globalStyles from './Styles';
import moment from 'moment';
import { followMatch, getMatches, getTournament } from "../store/actions/userActions";
import AlertDialog from './AlertDialog';

export const MatchCard = ({ match, navigation, token, deleteMatch, getMatches, getTournament, user, followMatch, action, setAction }) => {
    const role = token?.split(" ")[0]
    const [matchDate, setMatchDate] = React.useState(moment(match?.date).add(-3, "hours"))
    const [live, setLive] = React.useState(moment().isAfter(matchDate) && match?.started)
    const [time, setTime] = React.useState(moment(moment() - moment(match?.date)).minutes());

    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteMatch({ matchId: match._id })
        hideModal()
        setAction(!action)
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            match?.started && setTime(moment(moment() - moment(match?.startedAt)).minutes())
            setLive(!match?.ended && match?.started)
        }, 100);
        return () => {
            clearInterval(interval);
        };
    })

    return (
        <>
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete this match?`} okText={"CONFIRM"} />
            <Card style={defaultStyles.card} onPress={() => navigation.navigate("Match", { matchId: match._id })}>
                <View>
                    <Card.Content>
                        <View style={grid.container}>
                            {
                                live ?
                                    <View style={{}}>
                                        <Avatar.Text style={{ backgroundColor: "green" }} size={25} label={time} />
                                    </View> :
                                    match?.ended && <View style={{}}>
                                        <Avatar.Text style={{ backgroundColor: "gray" }} size={25} label="FT" />
                                    </View>
                            }
                            <View style={grid.leftColumn}>
                                <Text style={{ ...grid.textStyle, fontWeight: match.scoreA > match.scoreB ? "bold" : "normal" }} >{match.teamA.name}</Text>
                            </View>
                            <View style={grid.centerColumn}>
                                <Text style={{ ...grid.textStyle, ...(live ? { color: "green" } : {}) }}>{!match?.started ? `${moment(matchDate).format("hh:mm a")}` : `${match.scoreA}-${match.scoreB}`}</Text>
                            </View>
                            <View style={grid.rightColumn}>
                                <Text style={{ ...grid.textStyle, fontWeight: match.scoreA < match.scoreB ? "bold" : "normal" }}>{match.teamB.name}</Text>
                            </View>
                        </View>
                        <View style={grid.container}>
                            <View style={grid.centerColumn}>
                                <Text style={{ ...grid.textStyle, textAlign: "center" }}>{moment(matchDate).format("ll")}</Text>
                            </View>
                        </View>
                    </Card.Content>
                </View>
                {role && (role === "Super" || role === "Admin") &&
                    <Card.Actions>
                        <Divider style={{ marginBottom: 7 }} />
                        {role === "Super" && <Button onPress={showModal} icon="delete">Delete</Button>}
                        {!live && !match?.ended && <IconButton onPress={() => navigation.navigate("EndorStartMatch", { match })} icon="whistle" />}
                        {live && <IconButton onPress={() => navigation.navigate("EndorStartMatch", { match })} icon="whistle" />}
                    </Card.Actions>
                }
            </Card>
        </>
    )
}

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "3%",
    },
    textStyle: {
        fontSize: 13
    },
    leftColumn: {
        flex: 1,
        alignItems: 'flex-end',
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'flex-start',
    },
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteMatch,
    getMatches,
    getTournament,
    followMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchCard);