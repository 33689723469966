import React, { useEffect, useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text, ActivityIndicator, Checkbox, DataTable, ThemeProvider, DefaultTheme } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import { addOlympiad } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../AppBar";
import DropDown from "react-native-paper-dropdown";
import { getSports } from "../../app/store/actions/userActions";
import { PaperSelect } from "react-native-paper-select";

const CreateOlympics = ({ addOlympiad, getSports, isLoading, sports }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [sportIds, setSportIds] = useState([]);
    const [selectedSports, setSelectedSports] = useState([]);
    const [positionalPoints, setPositionalPoints] = useState([]);
    const [type, setType] = useState("League");
    const [maxTeamNumber, setMaxTeamNumber] = useState("");
    const [maxTeamSize, setMaxTeamSize] = useState("");
    const [minTeamSize, setMinTeamSize] = useState("");
    const [location, setLocation] = useState("");
    const [organizedFor, setOrganizedFor] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [matchDefaults, setMatchDefaults] = useState({
        duration: undefined,
        date: undefined
    })
    const { duration, date } = matchDefaults
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDown2, setShowDropDown2] = useState(false);

    const handleChangePositionalPoints = (text, idx) => {
        const points = [...positionalPoints]
        points[idx] = parseInt(text) || ""
        setPositionalPoints(points)
    }

    const handleMaxTeamNumberChange = (text) => {
        setMaxTeamNumber(text)
        setPositionalPoints(new Array(parseInt(text) || 0).fill(""))
    }

    useEffect(() => {
        getSports()
    }, [])

    const navigation = useNavigation();

    const handleCreateOlympics = () => {
        const creation = {
            name,
            description,
            sportIds: selectedSports
        }

        const tournamentCreation = {
            positionalPoints,
            type,
            maxTeamNumber,
            maxTeamSize,
            minTeamSize,
            location,
            organizedFor,
            startDate,
            matchDefaults,
            endDate,
        }
        addOlympiad({ creation, tournamentCreation }, navigation)
    };

    if (isLoading || sports === undefined) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title="Create Sports Day" />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Name"
                    value={name}
                    onChangeText={(text) => setName(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4, height: 100 }}
                    label="Description"
                    multiline
                    numberOfLines={5}
                    value={description}
                    onChangeText={(text) => setDescription(text)}
                />

                <PaperSelect
                    label="Sports"
                    textInputStyle={{ marginTop: 10 }}
                    value={selectedSports[0]?.value}
                    onSelection={(value) => { setSportIds(value.selectedList.map((sport) => { return sport._id })); setSelectedSports(value.selectedList) }}
                    arrayList={sports && sports.map((sport) => {
                        return {
                            value: sport.name,
                            _id: sport._id
                        }
                    })}
                    selectedArrayList={[...selectedSports]}
                    multiEnable={true}
                />

                <Divider />
                <Divider />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Max Team Number"
                    value={maxTeamNumber}
                    keyboardType="numeric"
                    onChangeText={handleMaxTeamNumberChange}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Max Team Size"
                    value={maxTeamSize}
                    keyboardType="numeric"
                    onChangeText={(text) => setMaxTeamSize(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Min Team Size"
                    value={minTeamSize}
                    keyboardType="numeric"
                    onChangeText={(text) => setMinTeamSize(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Location"
                    value={location}
                    onChangeText={(text) => setLocation(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Organized For"
                    value={organizedFor}
                    onChangeText={(text) => setOrganizedFor(text)}
                />

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Start Date"
                        value={startDate}
                        onChange={(d) => setStartDate(d)}
                        inputMode="start"
                    />
                </ThemeProvider>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="End Date"
                        value={endDate}
                        onChange={(d) => setEndDate(d)}
                        inputMode="end"
                    />
                </ThemeProvider>

                <View style={{ margin: 15 }}>
                    <DataTable style={globalStyles.table}>
                        <DataTable.Header style={globalStyles.header}>
                            <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Position</DataTable.Title>
                            <DataTable.Title style={{ flex: 4, justifyContent: "center", alignItems: "center" }} >Points</DataTable.Title>
                        </DataTable.Header>
                        {
                            positionalPoints.map((points, idx) => {
                                return (
                                    <View style={{ flexDirection: 'row' }} key={idx}>
                                        <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ flex: 2 }}>{idx + 1}</Text></View>
                                        <TextInput
                                            mode="outlined"
                                            style={{ flex: 4 }}
                                            label="Points"
                                            value={positionalPoints[idx]}
                                            keyboardType="numeric"
                                            onChangeText={(text) => handleChangePositionalPoints(text, idx)}
                                        />
                                    </View>
                                )
                            })
                        }
                    </DataTable>
                </View>
                <Divider />
                <Text>Match Defaults</Text>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Default Duration"
                    inputMode="numeric"
                    value={duration}
                    onChangeText={(text) => setMatchDefaults({ ...matchDefaults, duration: text })}
                />

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Defaults Matches Date"
                        value={date}
                        onChange={(d) => setMatchDefaults({ ...matchDefaults, date: d })}
                        inputMode="start"
                    />
                </ThemeProvider>

                <Button mode="contained" onPress={handleCreateOlympics}>
                    Create
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    sports: state?.records?.sports,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading
});

const mapDispatchToProps = {
    addOlympiad,
    getSports
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOlympics);