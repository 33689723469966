import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Linking, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { Appbar, Avatar, Button, Divider } from 'react-native-paper';
import AlertDialog from '../../app/components/AlertDialog';
import { logout } from '../../app/store/actions/authActions';
import globalStyles from '../../app/components/Styles';
import AppBar from '../../AppBar';
import roarImage from "../../app/components/images/ROAR.png";
import { deleteAccount } from '../../app/store/actions/userActions';

export const Profile = ({ user, logout, deleteAccount }) => {

    const navigation = useNavigation();
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteAccount({})
        hideModal()
    }

    return (
        <>
            <AppBar title="Profile" noBack action={<>
                {user && <Appbar.Action icon="delete" iconColor='#AB0000' onPress={showModal} />}
                <Appbar.Action icon="bell" onPress={() => navigation.navigate("Notifications")} />
            </>} />
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete your account and data? This action is irreversible!`} okText={"CONFIRM"} />
            <View style={{ flexDirection: "column", flexGrow: 1, justifyContent: 'space-between' }}>
                <View style={{ padding: 15 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
                        <Avatar.Icon icon="account" />
                        <View style={{ flexDirection: "column", marginStart: 10 }}>
                            {user && <Text style={{ fontSize: 30, fontWeight: "bold", marginBottom: 15 }}>{user?.name}</Text>}
                            {user ? <Button mode="contained" onPress={logout}>Logout</Button> : <Button mode="contained" onPress={() => navigation.navigate("Login")}>Log In</Button>}
                        </View>
                    </View>
                    <Divider />
                </View>

                <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Button mode="contained" onPress={() => navigation.navigate("Notifications")}>View Notifications</Button>
                </View>

                <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Image source={roarImage} style={{ width: 300, height: 81, marginTop: 10 }} />
                </View>

                <View style={{ padding: 15, backgroundColor: "#CDCACA" }}>
                    <Text>© ROAR 2025 All rights Reserved</Text>
                    {/* <Text style={{fontWeight: "bold"}} onPress={() => Linking.openURL("https://linktr.ee/integra.tech")}>Developed By Integra</Text> */}
                </View>
            </View>
        </>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user
});

const mapDispatchToProps = {
    logout,
    deleteAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);