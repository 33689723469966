import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Card, Drawer, IconButton, Text } from 'react-native-paper';
import MatchCard from './MatchCard';
import globalStyles from './Styles';

const TournamentDrawer = ({ matches, tournamentName, navigation, action, setAction }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(tournamentName == "Following");

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <Card style={globalStyles.card}>
            <View style={grid.container}>
                <View style={grid.leftColumn}>
                    <Text style={grid.textStyle}>{tournamentName}</Text>
                </View>
                <View style={grid.rightColumn}>
                    <IconButton onPress={toggleDrawer} icon={isDrawerOpen ? "chevron-up" : "chevron-down"} />
                </View>
            </View>
            <Card.Content style={globalStyles.container}>
                {
                    isDrawerOpen &&
                    matches.map((match, idx) => {
                        return <MatchCard match={match} key={idx} navigation={navigation} action={action} setAction={setAction} />
                    })
                }
            </Card.Content>
        </Card>
    );
};

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: "5%",
        paddingBottom: "5%"
    },
    textStyle: {
        fontSize: 20,
        fontWeight: "bold"
    },
    leftColumn: {
        flex: 1,
        alignItems: 'flex-start',
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'flex-end',
    },
});

export default TournamentDrawer;
