import React, { useEffect, useState } from "react";
import { View, ScrollView, Text } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator, SegmentedButtons, Chip, ThemeProvider, DefaultTheme } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../../app/components/Styles";
import { Divider } from '@rneui/themed';
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput, TimePickerModal } from "react-native-paper-dates";
import DropDown from "react-native-paper-dropdown";
import AppBar from "../../../AppBar";
import { editSession } from "../../../app/store/actions/superActions";
import { getCoaches } from "../../../app/store/actions/userActions";
import { PaperSelect } from "react-native-paper-select";

const EditSession = ({ editSession, isLoading, getCoaches, academyCoaches, route }) => {
    const { slot, academy } = route.params
    const navigation = useNavigation();
    const [formData, setFormData] = useState({
        startDate: new Date(slot.start),
        coachIds: slot.coaches,
        additionalInfo: slot.additionalInfo,
        location: slot.location,
    })
    const [startTime, setStartTime] = useState({ hours: moment(slot.start).hours(), minutes: moment(slot.start).minutes() })
    const [endTime, setEndTime] = useState({ hours: moment(slot.end).hours(), minutes: moment(slot.end).minutes() })
    const [selectedCoaches, setSelectedCoaches] = useState([])

    const [visible, setVisible] = useState(false)
    const onDismiss = React.useCallback(() => {
        setVisible(false)
    }, [setVisible])

    const onConfirm = React.useCallback(
        ({ hours, minutes }) => {
            setVisible(false);
            setStartTime({ hours, minutes });
        },
        [setVisible]
    );

    const [visible1, setVisible1] = useState(false)
    const onDismiss1 = React.useCallback(() => {
        setVisible1(false)
    }, [setVisible1])

    const onConfirm1 = React.useCallback(
        ({ hours, minutes }) => {
            setVisible1(false);
            setEndTime({ hours, minutes });
        },
        [setVisible1]
    );

    const handleChangeFormData = (name, val) => {
        setFormData({ ...formData, [name]: val })
    }

    const handleEditSession = () => {
        let form = { ...formData, startDate: moment(formData.startDate).format("YYYY-MM-DD"), startTime: startTime, endTime: endTime }
        console.log(form)
        editSession({ slotId: slot._id, edits: form }, navigation)
    };

    useEffect(() => {
        getCoaches({ page: 1, limit: 500, academyId: academy._id })
    }, [])

    useEffect(() => {
        if (academyCoaches) {
            const selected = academyCoaches?.filter((coach) => slot.coaches.includes(coach.coach._id))
            setSelectedCoaches(selected?.map((coach) => { return { _id: coach.coach._id, value: coach.name } }))
        }
    }, [academyCoaches])

    if (isLoading || !academyCoaches) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View>
            <AppBar navigation={navigation} title={"Edit Training Session"} />
            <ScrollView style={{ padding: 30 }} contentContainerStyle={{ justifyContent: "center" }}>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginTop: 8 }}
                        label="Date"
                        value={formData.startDate}
                        onChange={(d) => handleChangeFormData("startDate", d)}
                    />
                </ThemeProvider>

                <Button style={{ marginVertical: 8 }} onPress={() => setVisible(true)} uppercase={false} mode="outlined">
                    Pick start time
                </Button>

                <Button style={{ marginVertical: 8 }} onPress={() => setVisible1(true)} uppercase={false} mode="outlined">
                    Pick end time
                </Button>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#ffffff',
                        onPrimary: "#000B26",
                        onBackground: '#000B26',
                        secondaryContainer: '#ffffff',
                        onSecondary: "#000B26",
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff"
                    }
                }}>
                    <TimePickerModal
                        locale="en-GB"
                        visible={visible}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        hours={startTime.hours}
                        minutes={startTime.minutes}
                    />
                </ThemeProvider>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#ffffff',
                        onPrimary: "#000B26",
                        onBackground: '#000B26',
                        secondaryContainer: '#ffffff',
                        onSecondary: "#000B26",
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff"
                    }
                }}>
                    <TimePickerModal
                        locale="en-GB"
                        visible={visible1}
                        onDismiss={onDismiss1}
                        onConfirm={onConfirm1}
                        hours={endTime.hours}
                        minutes={endTime.minutes}
                    />
                </ThemeProvider>

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Additional Information"
                    value={formData.additionalInfo}
                    onChangeText={(text) => handleChangeFormData("additionalInfo", text)}
                />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Location URL (if different from default)"
                    value={formData.location}
                    onChangeText={(text) => handleChangeFormData("location", text)}
                />

                <PaperSelect
                    label="Coaches"
                    textInputStyle={{ marginTop: 10 }}
                    value={selectedCoaches[0]?.value}
                    onSelection={(value) => { handleChangeFormData("coachIds", value.selectedList.map((coach) => { return coach._id })); setSelectedCoaches(value.selectedList) }}
                    arrayList={academyCoaches && academyCoaches.map((coach) => {
                        return {
                            value: coach.name,
                            _id: coach.coach._id
                        }
                    })}
                    selectedArrayList={[...selectedCoaches]}
                    multiEnable={true}
                />

                <Button style={{ marginTop: 8 }} mode="contained" onPress={handleEditSession}>
                    Edit Slot
                </Button>
            </ScrollView>
        </View>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.wait?.isLoading || state?.records?.isLoading,
    academyCoaches: state?.records?.coaches?.docs,
});

const mapDispatchToProps = {
    editSession,
    getCoaches
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSession);