import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Button, DefaultTheme, TextInput } from 'react-native-paper';
import { loginUser, signUp } from '../../app/store/actions/authActions';
import globalStyles from '../../app/components/Styles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import PhoneInput from 'react-native-phone-input'
import AppBar from '../../AppBar';

export const Login = ({ user, token, loginUser, signUp, isLoading }) => {

    const navigation = useNavigation();
    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [userData, setUserData] = React.useState({
        name: undefined,
        username: undefined,
        email: undefined,
        mobile: undefined,
        password: undefined
    })

    const { name, username, email, mobile, password: signUpPassword } = userData

    const handleLogin = async () => {
        loginUser({
            login,
            password
        })
    }

    const handleSignup = async () => {
        if (!email || !name || !username || !signUpPassword) {
            alert('Please fill all fields')
        }
        signUp({
            name,
            username,
            email,
            mobile,
            password: signUpPassword
        })
    }

    

    useEffect(() => {
        if (user) {
            try{
                window.ReactNativeWebView.postMessage(token);
            } catch (err) {
                console.log(err)
            }
            navigation.navigate("Profile")
        }
    }, [user])

    useEffect(() => {
        if (user) {
            try{
                window.ReactNativeWebView.postMessage(token);
            } catch (err) {
                console.log(err)
            }
            navigation.goBack()
        }
    }, [])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <>
            <AppBar navigation={navigation} title={"Login"} />
            <TabsProvider defaultIndex={0} >
                <Tabs showLeadingSpace={false} theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff",
                        onSurface: '#ffffff',
                    },
                }}>
                    <TabScreen label="Login">
                        <View style={globalStyles.container}>
                            <TextInput
                                mode="outlined"
                                label="Email, Username or Mobile"
                                value={login}
                                onChangeText={setLogin}
                                style={globalStyles.input}
                            />
                            <TextInput
                                mode="outlined"
                                label="Password"
                                value={password}
                                onChangeText={setPassword}
                                secureTextEntry
                                style={globalStyles.input}
                            />
                            <Button mode="contained" onPress={async () => await handleLogin()}>
                                Login
                            </Button>
                            <Button mode="text" onPress={() => navigation.navigate("Reset")}>
                                Forgot Password
                            </Button>
                        </View>
                    </TabScreen>
                    <TabScreen label="Sign Up">
                        <View style={globalStyles.container}>
                            <TextInput
                                mode="outlined"
                                label="Name*"
                                value={name}
                                onChangeText={(text) => setUserData({ ...userData, name: text })}
                                style={globalStyles.input}
                            />
                            <TextInput
                                mode="outlined"
                                label="Username*"
                                value={username}
                                onChangeText={(text) => setUserData({ ...userData, username: text })}
                                style={globalStyles.input}
                            />
                            <TextInput
                                mode="outlined"
                                label="Email*"
                                inputMode="email"
                                value={email}
                                onChangeText={(text) => setUserData({ ...userData, email: text })}
                                style={globalStyles.input}
                            />
                            <TextInput
                                mode="outlined"
                                label="Mobile"
                                inputMode="numeric"
                                value={mobile}
                                onChangeText={(text) => setUserData({ ...userData, mobile: text })}
                                style={globalStyles.input}
                            />
                            <TextInput
                                mode="outlined"
                                label="Password*"
                                id='passsword'
                                value={signUpPassword}
                                onChangeText={(text) => setUserData({ ...userData, password: text })}
                                secureTextEntry
                                style={globalStyles.input}
                            />
                            <Button mode="contained" onPress={async () => await handleSignup()}>
                                Sign Up
                            </Button>
                        </View>
                    </TabScreen>
                </Tabs>
            </TabsProvider>
        </>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token,
    isLoading: state?.auth?.isLoading
});

const mapDispatchToProps = { loginUser, signUp };

export default connect(mapStateToProps, mapDispatchToProps)(Login);