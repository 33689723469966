import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Divider, Menu, Modal, Portal, TextInput, IconButton } from 'react-native-paper';
import roarImage from "../../app/components/images/ROAR.png";
import AppBar from '../../AppBar';
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { getAcademy } from '../../app/store/actions/userActions';
import { RefreshControl } from 'react-native';
import globalStyles from '../../app/components/Styles';
import AcademyCard from '../../app/components/AcademyCard';
import { Agenda, Calendar, AgendaEntry } from 'react-native-calendars';
import moment from 'moment';
import { StyleSheet, TouchableOpacity } from 'react-native';
import CoachCard from '../../app/components/CoachCard';
import SessionCard from '../../app/components/SessionCard';
import { Dimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

export const SingleAcademy = ({ user, trainee, coach, getAcademy, isLoading, academy, slots, route, coaches }) => {

    const navigation = useNavigation();
    const screenWidth = Dimensions.get('window').width;
    const screenHeight = Dimensions.get('window').height;
    const { academyId } = route.params
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
    const [sessionsToday, setSessionsToday] = useState([]);
    const [action, setAction] = useState(true)

    const refresher = async () => {
        getAcademy({ academyId })
    }

    useEffect(() => {
        refresher()
    }, [user, academyId, action])

    useEffect(() => {
        if (Array.isArray(slots)) {
            getToday()
        }
    }, [isLoading, slots])

    const getToday = () => {
        const slotsToday = slots?.filter((slot) => moment(slot.start).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
        setSessionsToday(slotsToday)
        console.log(slotsToday)
    };

    const renderCoach = ({ item, index }) => {
        return (
            <View style={{ ...globalStyles.container }}>
                <CoachCard key={item._id} coach={item} />
            </View>
        );
    };
    const [coachIndex, setCoachIndex] = useState(0)

    const renderSession = ({ item, index }) => {
        return (
            <View style={{ ...globalStyles.container }}>
                <SessionCard key={item._id} session={item} navigation={navigation} action={action} setAction={setAction} />
            </View>
        );
    };
    const [sessionIndex, setSessionIndex] = useState(0)

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={{ marginBottom: 120 }}>
            <AppBar navigation={navigation} title={`${academy?.title}`} action={<>
                {trainee && <Appbar.Action icon="qrcode" onPress={showModal} />}
                {coach && <Appbar.Action icon="qrcode" onPress={showModal} />}
                {user?.type === "Super" && <Appbar.Action icon="pencil" onPress={() => navigation.navigate("EditAcademy", { academy, coaches })} />}
                {user?.type === "User" && !trainee && !coach && <Appbar.Action icon="account-plus-outline" onPress={() => navigation.navigate("Enroll")} />}
                <Appbar.Action icon="calendar" onPress={() => navigation.navigate("Agenda", { academy, coaches, slots })} />
            </>} />
            <ScrollView style={{ paddingHorizontal: 30 }} contentContainerStyle={{ justifyContent: "center", alignItems: "center" }} refreshControl={<RefreshControl onRefresh={refresher} />}>
                {trainee && <Button mode="contained" style={{ marginVertical: 15 }} onPress={() => navigation.navigate("Reserve", { academy })}>Reserve Sessions in Academy</Button>}
                {user?.type === "User" && (
                    <View>
                        <Portal>
                            <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={user?._id}
                                />
                            </Modal>
                        </Portal>
                    </View>
                )}
                {coaches && coaches.length > 0 && <>
                    <Text style={{ fontSize: 25, marginBottom: 10, marginTop: 5 }}>Meet The Coaches</Text>
                    <Carousel
                        loop
                        width={screenWidth}
                        height={screenHeight / 2.5}
                        data={coaches}
                        scrollAnimationDuration={1000}
                        renderItem={({ index, item }) => (
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CoachCard key={item._id} coach={item} />
                            </View>
                        )}
                    />
                </>}
                <Text style={{ fontSize: 25, marginBottom: 10, marginTop: 5 }}>Today's Sessions</Text>
                <Button icon="plus" onPress={() => navigation.navigate("Agenda", { academy, coaches, slots })}>View More</Button>
                {sessionsToday?.length > 0 && <View>
                    <SessionCard key={sessionsToday[0]._id} session={sessionsToday[0]} navigation={navigation} action={action} setAction={setAction} />
                </View>}
                {(!sessionsToday || sessionsToday.length === 0) && <Text style={{ fontSize: 20, fontWeight: "bold" }}>No More Sessions Today</Text>}
                <Divider />
                {user?.type === "Super" && <Button mode="contained" style={{ marginVertical: 15 }} onPress={() => navigation.navigate("AddSessions", { academy, coaches })}>Add Sessions to Academy</Button>}
            </ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    trainee: state?.auth?.user?.trainee,
    coach: state?.auth?.user?.coach,
    isLoading: state?.records?.isLoading,
    academy: state?.records?.academy,
    slots: state?.records?.slots,
    coaches: state?.records?.coaches
});

const mapDispatchToProps = { getAcademy };

export default connect(mapStateToProps, mapDispatchToProps)(SingleAcademy);
