import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { AddTeamPlayers, CreateMatch, CreateTeam, CreateTournament, EditMatch, EditMatchEvent, EditTeam, EndorStartMatch, Match, MatchUpdate, SingleOlympiad, SingleTeam, Tournament, Tournaments } from "../Pages";
import { NavigationContainer } from "@react-navigation/native";

const Stack = createStackNavigator();

export default function TournamentRouter({ jumpTo }) {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Tournaments">
        <Stack.Screen name="Tournaments" options={{ headerShown: false, jumpTo }} component={Tournaments} />
        <Stack.Screen name="CreateTeam" options={{ headerShown: false }} component={CreateTeam} />
        <Stack.Screen name="CreateMatch" options={{ headerShown: false }} component={CreateMatch} />
        <Stack.Screen name="CreateTournament" options={{ headerShown: false }} component={CreateTournament} />
        <Stack.Screen name="Tournament" options={{ headerShown: false }} component={Tournament} />
        <Stack.Screen name="UpdateMatch" options={{ headerShown: false }} component={MatchUpdate} />
        <Stack.Screen name="EditMatchEvent" options={{ headerShown: false }} component={EditMatchEvent} />
        <Stack.Screen name="EndorStartMatch" options={{ headerShown: false }} component={EndorStartMatch} />
        <Stack.Screen name="Match" options={{ headerShown: false }} component={Match} />
        <Stack.Screen name="Olympiad" options={{ headerShown: false }} component={SingleOlympiad} />
        <Stack.Screen name="AddPlayers" options={{ headerShown: false }} component={AddTeamPlayers} />
        <Stack.Screen name="Team" options={{ headerShown: false }} component={SingleTeam} />
        <Stack.Screen name="EditTeam" options={{ headerShown: false }} component={EditTeam} />
        <Stack.Screen name="EditMatch" options={{ headerShown: false }} component={EditMatch} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
