import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef, useCallback } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Card, DefaultTheme, Divider, IconButton, Menu, TextInput, ThemeProvider } from 'react-native-paper';
import { getMatches } from '../../app/store/actions/userActions';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import globalStyles from "../../app/components/Styles";
import TournamentDrawer from '../../app/components/TournamentDrawer';
import moment from 'moment';
import AppBar from '../../AppBar';
import { getSports } from "../../app/store/actions/userActions";
import DropDown from 'react-native-paper-dropdown';
import { DatePickerModal } from 'react-native-paper-dates';
import { AntDesign } from '@expo/vector-icons';
import SportCard from '../../app/components/SportCard';

export const ListSports = ({ user, isLoading, getSports, sports }) => {

    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const [action, setAction] = useState(false);

    const refresher = async () => {
        getSports();
    }

    useEffect(() => {
        refresher()
    }, [action])

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title="Sports" action={<>
                <Appbar.Action icon="plus" onPress={() => navigation.navigate("CreateSport")} />
            </>} />
            <View style={{ ...globalStyles.container, marginTop: 50 }}>
                {
                    isLoading && (
                        <View style={globalStyles.container}>
                            <ActivityIndicator />
                        </View>
                    )}
                {
                    sports && sports.map((sport, idx) => {
                        return (
                            <SportCard key={idx} sport={sport} action={action} setAction={setAction} />
                        )
                    })
                }
                {
                    sports && sports.length === 0 && (
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <AntDesign name="dropbox" size={100} color="black" />
                            <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                        </View>
                    )
                }
            </View>
        </ScrollView>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    sports: state?.records?.sports,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getSports
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSports);