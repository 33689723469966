import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, DataTable, DefaultTheme, Drawer, Modal, Portal, TextInput } from 'react-native-paper';
import { getTeam, followMatch, unfollowMatch } from '../../app/store/actions/userActions';
import globalStyles from '../../app/components/Styles';
import AppBar from '../../AppBar';
import TeamCard from '../../app/components/TeamCard';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import MatchCard from '../../app/components/MatchCard';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import { StyleSheet } from 'react-native';
import AlertDialog from '../../app/components/AlertDialog';
import { deletePlayer, editPlayer } from '../../app/store/actions/adminActions';

export const Team = ({ token, user, getTeam, isLoading, team, players, matches, nextMatch, editPlayer, deletePlayer, followMatch, route }) => {

    const { teamId } = route.params;
    const navigation = useNavigation();
    const role = token?.split(" ")[0];
    const isFocused = useIsFocused();
    const [goalsSort, setGoalsSort] = useState(null);
    const [assistsSort, setAssistsSort] = useState(null);
    const [yellowSort, setYellowSort] = useState(null);
    const [redSort, setRedSort] = useState(null);
    const [sortedPlayers, setSortedPlayers] = useState(null);
    const [action, setAction] = useState(false)

    const [visible, setVisible] = useState(false);
    const [player, setPlayer] = useState(undefined);
    const [playerName, setPlayerName] = useState("");
    const showModal = (player) => {
        setPlayer(player)
        setVisible(true)
    };
    const hideModal = () => setVisible(false);

    const [visibleDel, setVisibleDel] = useState(false);
    const showModalDel = () => setVisibleDel(true)
    const hideModalDel = () => setVisibleDel(false);

    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };

    const handleEditPlayer = async () => {
        try {
            const edits = {
                name: playerName
            }

            editPlayer({ edits, playerId: player.player._id })
            hideModal()
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeletePlayer = async () => {
        try {
            console.log({ playerId: player.player._id })
            deletePlayer({ playerId: player.player._id })
            hideModalDel()
        } catch (error) {
            console.log(error);
        }
    };

    const sortPlayers = (target) => {
        switch (target) {
            case "Goals":
                if (goalsSort === "descending") {
                    setGoalsSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.goalCount - b.goalCount));
                } else {
                    setGoalsSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.goalCount - a.goalCount));
                }
                setAssistsSort(null);
                setYellowSort(null);
                setRedSort(null);
                break;
            case "Assists":
                if (assistsSort === "descending") {
                    setAssistsSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.assistCount - b.assistCount));
                } else {
                    setAssistsSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.assistCount - a.assistCount));
                }
                setGoalsSort(null);
                setYellowSort(null);
                setRedSort(null);
                break;
            case "Red":
                if (redSort === "descending") {
                    setRedSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.redCount - b.redCount));
                } else {
                    setRedSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.redCount - a.redCount));
                }
                setGoalsSort(null);
                setYellowSort(null);
                setAssistsSort(null);
                break;
            case "Yellow":
                if (yellowSort === "descending") {
                    setYellowSort("ascending");
                    setSortedPlayers(players?.sort((a, b) => a.yellowCount - b.yellowCount));
                } else {
                    setYellowSort("descending");
                    setSortedPlayers(players?.sort((a, b) => b.yellowCount - a.yellowCount));
                }
                setGoalsSort(null);
                setRedSort(null);
                setAssistsSort(null);
                break;
            default:
                setAssistsSort(null);
                setYellowSort(null);
                setRedSort(null);
                setGoalsSort("descending")
                setSortedPlayers(players?.sort((a, b) => b.goalCount - a.goalCount));
                break;
        }
    }

    const refresher = async () => {
        getTeam({ teamId })
        const device = await Device.getDeviceTypeAsync()
        if (device !== Device.DeviceType.DESKTOP) {
            connectWebSocket()
        }
    }

    useEffect(() => {
        if (isFocused) {
            refresher()
        } else {
            disconnectWebSocket()
        }
    }, [isFocused, teamId, action])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <>
            <AppBar navigation={navigation} title={team?.name} action={<>
                {role === "Super" && <Appbar.Action icon="pencil" onPress={() => navigation.navigate('EditTeam', { team, tournament: team?.tournament })} />}
                {role === "User" && (
                    user.teamsFollowed.includes(team?._id) ?
                        <Appbar.Action onPress={() => unfollowMatch({ team: team?._id })} icon="star" iconColor='gold' /> :
                        <Appbar.Action onPress={() => followMatch({ team: team?._id })} icon="star-outline" />)}
            </>} />
            <TabsProvider defaultIndex={0}>
                <Tabs mode="scrollable" showLeadingSpace={false} theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff",
                        onSurface: '#ffffff',
                    },
                }}>
                    {/* <TabScreen label='Info'>
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={globalStyles.container}>
                        </ View>
                    </ScrollView>
                </TabScreen> */}
                    <TabScreen label='Matches'>
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={globalStyles.container}>
                                {
                                    matches?.map((match, i) => {
                                        return <MatchCard key={i} navigation={navigation} match={match} action={action} setAction={setAction} />
                                    })
                                }
                            </ View>
                        </ScrollView>
                    </TabScreen>
                    {/* <TabScreen label="Players">
                    <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                        <View style={globalStyles.container}>
                            {role && role === "Super" &&
                                <View>
                                    <Button
                                        mode="contained"
                                        onPress={() => navigation.navigate('AddPlayers', { team })}
                                    >
                                        Add Players
                                    </Button>
                                </View>
                            }
                        </View>
                    </ScrollView>
                </TabScreen> */}
                    <TabScreen label="Players" onPress={sortPlayers}>
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={globalStyles.container}>
                                <AlertDialog visible={visibleDel} handleAccept={handleDeletePlayer} handleClose={hideModalDel} message={`Are you sure you want to delete ${player?.player.name}`} okText={"CONFIRM"} />
                                <View>
                                    <Portal>
                                        <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                            <Text style={{ textAlign: "center" }}>Edit "{player?.player.name}"</Text>
                                            <TextInput
                                                mode="outlined"
                                                style={{ marginVertical: 4, width: "100%" }}
                                                label="Name"
                                                value={playerName}
                                                onChangeText={setPlayerName}
                                                required
                                            />

                                            <Button
                                                mode="contained"
                                                style={styles.button}
                                                onPress={handleEditPlayer}
                                            >
                                                Edit
                                            </Button>

                                            <Button
                                                mode="contained"
                                                style={styles.button}
                                                onPress={showModalDel}
                                            >
                                                Delete
                                            </Button>
                                        </Modal>
                                    </Portal>
                                </View>

                                <DataTable style={globalStyles.table}>
                                    <DataTable.Header style={globalStyles.header}>
                                        <DataTable.Title style={{ flex: 2 }}>Name</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={goalsSort} onPress={() => sortPlayers("Goals")} >Goals</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={assistsSort} onPress={() => sortPlayers("Assists")}>Assists</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={yellowSort} onPress={() => sortPlayers("Yellow")} >Yellow</DataTable.Title>
                                        <DataTable.Title style={{ flex: 1 }} numeric sortDirection={redSort} onPress={() => sortPlayers("Red")}>Red</DataTable.Title>
                                    </DataTable.Header>

                                    {sortedPlayers?.map((player) => (
                                        <DataTable.Row key={player._id} onPress={() => {
                                            if (role === "Super") {
                                                showModal(player)
                                            }
                                        }}>
                                            <DataTable.Cell style={{ flex: 2 }} > <Text style={{ fontWeight: player.isBanned.length > 0 ? "bold" : "normal", color: player.isBanned.length > 0 ? "red" : null }}>{player.player.name} {player.isBanned.length > 0 ? ` (${player.isBanned[0].cautionType})` : ""}</Text></DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.goalCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.assistCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.yellowCount}</DataTable.Cell>
                                            <DataTable.Cell style={{ flex: 1 }} numeric>{player.redCount}</DataTable.Cell>
                                        </DataTable.Row>
                                    ))}
                                </DataTable>
                                {role && role === "Super" &&
                                    <View>
                                        <Button
                                            mode="contained"
                                            onPress={() => navigation.navigate('AddPlayers', { team })}
                                        >
                                            Add Players
                                        </Button>
                                    </View>
                                }
                            </View>
                        </ScrollView>
                    </TabScreen>
                </Tabs>
            </TabsProvider>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        marginVertical: 10,
        width: '80%',
    },
});

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    nextMatch: state?.records?.nextMatch,
    matches: state?.records?.matches,
    team: state?.records?.team,
    players: state?.records?.players,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getTeam,
    followMatch,
    connectWebSocket,
    disconnectWebSocket,
    editPlayer,
    deletePlayer
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);