import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Modal, Portal, Text } from 'react-native-paper';
import { View, StyleSheet, Dimensions } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import globalStyles from './Styles';
import moment from 'moment';
import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import AlertDialog from './AlertDialog';
import { deleteCoach } from '../store/actions/superActions';

export const CoachCard = ({ coach, token, user, action, setAction, deleteCoach }) => {
    const role = user?.type
    const navigation = useNavigation()
    const screenWidth = Dimensions.get('window').width;
    const screenHeight = Dimensions.get('window').height;

    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
    const [visible, setVisible] = useState(false)
    const hideModal = () => {
        setVisible(false)
    }
    const showModal = () => {
        setVisible(true)
    }

    const [visibleDelete, setVisibleDelete] = React.useState(false);
    const showDeleteModal = () => setVisibleDelete(true);
    const hideDeleteModal = () => setVisibleDelete(false);

    const handleDelete = () => {
        deleteCoach({ coachId: coach.coach._id })
        hideDeleteModal()
        if (action) {
            setAction(!action)
        }
    }

    return (
        <Card style={{width: screenWidth - 50, height: (screenHeight / 2.5 )}}>
            <Card.Cover source={coach.coach.image} />
            <Card.Title titleStyle={{ fontWeight: "bold" }} title={coach.name} />
            <Card.Actions>

            </Card.Actions>

            <Card.Actions>
                <Divider style={{ marginBottom: 7 }} />
                <Button icon="information" mode="text" onPress={showModal}>Learn More</Button>
                {role && role === "Super" && <>
                    <Button onPress={() => navigation.navigate("EditCoach", { coach })} icon="pencil">Edit</Button>
                    <Button onPress={showDeleteModal} icon="delete">Delete</Button>
                </>}
            </Card.Actions>

            <View>
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                        <Text variant="titleLarge" style={{ fontWeight: "bold", marginBottom: 10 }}>About {coach.name}</Text>
                        <Text variant="bodyMedium" style={{ textAlign: "justify" }}>{coach.coach.bio}</Text>
                    </Modal>
                </Portal>
            </View>

            <AlertDialog visible={visibleDelete} handleAccept={handleDelete} handleClose={hideDeleteModal} message={`Are you sure you want to delete this coach?`} okText={"CONFIRM"} />
        </Card>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user
});

const mapDispatchToProps = {
    deleteCoach
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachCard);