import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl, Dimensions } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, DataTable, Divider, Drawer, Menu } from 'react-native-paper';
import { TabScreen, Tabs } from 'react-native-paper-tabs';
import { getTraineeAttendance } from '../../../app/store/actions/userActions';
import AppBar from '../../../AppBar';
import globalStyles from '../../../app/components/Styles';
import moment from 'moment';
import DropDown from 'react-native-paper-dropdown';

export const TraineeAttendance = ({ token, user, isLoading, attendances, getTraineeAttendance }) => {

    const navigation = useNavigation();
    const [month, setMonth] = useState(moment().month())
    const [filters, setFilters] = useState({
        page: 1,
        limit: 500,
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    })

    const handleChangeFilter = (value) => {
        setMonth(value)
        setFilters({
            ...filters,
            startDate: moment().month(value).startOf('month').format('YYYY-MM-DD'),
            endDate: moment().month(value).endOf('month').format('YYYY-MM-DD'),
        })
    }

    const [filter, setFilter] = React.useState(false)
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const openFilter = () => {
        setFilter(true)
    }

    const closeFilter = () => {
        setFilter(false)
    }

    const [showDropDown, setShowDropDown] = useState(false)
    const openDropDown = () => {
        setShowDropDown(true)
    }

    const closeDropDown = () => {
        setShowDropDown(false)
    }

    const handleClearFilter = () => {
        setFilters({
            page: 1,
            limit: 500,
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
        })
        setMonth(moment().month())
    }

    const refresher = async () => {
        getTraineeAttendance(filters)
    }

    useEffect(() => {
        refresher()
    }, [user])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"My Attendance"} action={<>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("TraineeReservations"); closeMenu() }} leadingIcon="bookmark-check" title="My Reservations" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineePayments"); closeMenu() }} leadingIcon="wallet" title="My Balance" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineeAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                </Menu>
                <Menu
                    visible={filter}
                    onDismiss={closeFilter}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="filter-outline" id="filter-menu" onPress={openFilter} />}>
                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Month"}
                        mode={"outlined"}
                        visible={showDropDown}
                        dropDownStyle={{ marginVertical: 4, marginHorizontal: 5 }}
                        showDropDown={openDropDown}
                        onDismiss={closeDropDown}
                        value={month}
                        setValue={handleChangeFilter}
                        list={[
                            { label: "January", value: 0 },
                            { label: "February", value: 1 },
                            { label: "March", value: 2 },
                            { label: "April", value: 3 },
                            { label: "May", value: 4 },
                            { label: "June", value: 5 },
                            { label: "July", value: 6 },
                            { label: "August", value: 7 },
                            { label: "September", value: 8 },
                            { label: "October", value: 9 },
                            { label: "November", value: 10 },
                            { label: "December", value: 11 }
                        ]}
                    />
                    <Divider />
                    <Button onPress={refresher}>Apply Filters</Button>
                    <Button onPress={handleClearFilter}>Clear Filters</Button>
                </Menu>
            </>} />
            <View style={globalStyles.container}>
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 3 }}>Academy</DataTable.Title>
                        <DataTable.Title style={{ flex: 3 }}>Date</DataTable.Title>
                    </DataTable.Header>

                    {attendances?.map((attendance) => (
                        <DataTable.Row key={attendance._id}>
                            <DataTable.Cell style={{ flex: 3 }}><Text>{attendance.academy.title}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 3 }}><Text>{moment(attendance.createdAt).format("lll")}</Text></DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    attendances: state?.records?.attendances?.docs,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getTraineeAttendance
};

export default connect(mapStateToProps, mapDispatchToProps)(TraineeAttendance);