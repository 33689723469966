import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'react-native-paper';

export const Home = ({ }) => {

    const navigation = useNavigation();

    return (
        <View
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-around',
                marginBottom: 80
            }}>
            <Text>Welcome Home</Text>
            <Button icon="ab-testing" mode="outlined" onPress={() => navigation.navigate("TestHome")}>Go To Test Home</Button>
        </View>
    );
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);