import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Button, Card, DataTable, IconButton, TextInput } from 'react-native-paper';
import globalStyles from '../../../app/components/Styles';
import AppBar from '../../../AppBar';
import { DatePickerInput } from 'react-native-paper-dates';
import DropDown from 'react-native-paper-dropdown';
import { ScrollView } from 'react-native-gesture-handler';
import moment, { isMoment } from 'moment';
import { traineeReserve } from '../../../app/store/actions/userActions';

export const Reserve = ({ user, isLoading, traineeReserve, route }) => {

    const navigation = useNavigation();
    const { academy } = route.params;
    const [cost, setCost] = useState(0);
    const [showDropDown, setShowDropDown] = useState(false)
    const slots = Object.keys(academy.paymentScheme)
    const minSessions = parseInt(slots[0])

    useEffect(() => {
        if (!user.trainee) navigation.navigate("AcademyHome")
    }, [user])

    const [formData, setFormData] = React.useState({
        sessions: null,
        academyId: academy._id,
        method: "Cash",
        ipusername: null
    })

    const { sessions, academyId, method, ipusername } = formData
    const handleSessions = (sessions) => {
        const sessionsNo = parseInt(sessions) || 0
        var cost = 0
        for (let i = 0; i < slots.length - 1; i++) {
            if (sessionsNo >= parseInt(slots[i]) && sessionsNo < parseInt(slots[i + 1])) {
                cost = sessionsNo * academy.paymentScheme[slots[i]]
                break
            }
        }
        if (cost === 0) cost = sessionsNo * sessionsNo < minSessions ? academy.paymentScheme[slots[0]] : academy.paymentScheme[slots[slots.length - 1]]
        setCost(cost)
        setFormData({ ...formData, sessions: sessionsNo })
    }



    const handleReserve = async () => {
        console.log(formData)
        if (!sessions || (method === "InstaPay" && !ipusername)) {
            alert('Please fill all fields')
        } else if (sessions < minSessions) {
            alert(`Please reserve atleast ${minSessions} sessions`)
        } else {
            traineeReserve({
                academyId,
                sessions
            })
            navigation.goBack()
        }
    }

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView >
            <AppBar navigation={navigation} title={"Reserve Academy"} />
            <View style={{ padding: 30 }}>
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 3 }}>Sessions</DataTable.Title>
                        <DataTable.Title style={{ flex: 3 }}>Price per session</DataTable.Title>
                    </DataTable.Header>

                    {academy && academy.paymentScheme && Object.keys(academy.paymentScheme).map((payment, idx) => (
                        <DataTable.Row key={idx}>
                            <DataTable.Cell style={{ flex: 3 }}><Text>{payment}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 3 }}><Text>{academy.paymentScheme[payment]}EGP</Text></DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
                <DropDown
                    dropDownItemTextStyle={{ color: "whitesmoke" }}
                    dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Payment Method"}
                    mode={"outlined"}
                    visible={showDropDown}
                    dropDownStyle={{ marginVertical: 4, marginHorizontal: 5 }}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={method}
                    setValue={(val) => setFormData({ sessions, academyId, ipusername, method: val })}
                    list={[
                        { label: "Cash", value: "Cash" },
                        { label: "InstaPay", value: "InstaPay" },
                    ]}
                />
                {method === "InstaPay" && <TextInput
                    mode="outlined"
                    label="InstaPay Username (example@instapay)*"
                    value={ipusername}
                    onChangeText={(text) => setFormData({ ...formData, ipusername: text })}
                />}

                <Text>Minumum {minSessions} sessions</Text>
                <TextInput
                    mode="outlined"
                    label="Number of sessions*"
                    value={sessions}
                    keyboardType="numeric"
                    onChangeText={handleSessions}
                />
                <Text>Note that reservation is valid for only 2 months from today</Text>

                <Card style={{ ...globalStyles.card, marginVertical: 20 }}>
                    <Card.Title titleStyle={{ fontWeight: "bold" }} title={`Total Cost: ${cost}EGP`} />
                    <Card.Content>
                        <Text variant="bodyLarge" >Payments to be fulfilled with Mr Andrew Nabil</Text>
                        <Text>Send Through InstaPay: +201222817503</Text>
                        <Text>Or arrange cash payment through WhatsApp</Text>
                    </Card.Content>
                    <Card.Actions>
                        <IconButton icon="whatsapp" onPress={() => Linking.openURL("https://wa.me/201222817503")} />
                    </Card.Actions>
                </Card>
                <Button mode="contained" style={{ marginTop: 10 }} onPress={async () => await handleReserve()}>
                    Reserve Academy Sessions
                </Button>
            </View>
        </ScrollView>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = { traineeReserve };

export default connect(mapStateToProps, mapDispatchToProps)(Reserve);