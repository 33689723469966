import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, ScrollView, RefreshControl, Dimensions, Linking } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Card, DataTable, Divider, Drawer, IconButton, Menu } from 'react-native-paper';
import { TabScreen, Tabs } from 'react-native-paper-tabs';
import { getTraineePayments } from '../../../app/store/actions/userActions';
import AppBar from '../../../AppBar';
import globalStyles from '../../../app/components/Styles';
import moment from 'moment';

export const TraineePayments = ({ token, user, isLoading, payments, outstandingBalance, getTraineePayments }) => {

    const navigation = useNavigation();

    const [filters, setFilters] = useState({
        page: 1,
        limit: 500,
        startDate: undefined,
        endDate: undefined,
        status: undefined
    })

    const handleChangeFilter = (obj) => {
        console.log(obj)
    }

    const [filter, setFilter] = React.useState(false)
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const openFilter = () => {
        setFilter(true)
    }

    const closeFilter = () => {
        setFilter(false)
    }

    const handleClearFilter = () => {
        setFilters({
            page: 1,
            limit: 500,
            startDate: undefined,
            endDate: undefined,
            status: undefined
        })
    }

    const refresher = async () => {
        getTraineePayments(filters)
    }

    useEffect(() => {
        refresher()
    }, [user])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"My Balance"} action={<>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("TraineeReservations"); closeMenu() }} leadingIcon="bookmark-check" title="My Reservations" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineePayments"); closeMenu() }} leadingIcon="wallet" title="My Balance" />
                    <Menu.Item onPress={() => { navigation.navigate("TraineeAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                </Menu>
                {/* <Menu
                    visible={filter}
                    onDismiss={closeFilter}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="filter-outline" id="filter-menu" onPress={openFilter} />}>

                    <Divider />
                    <Button onPress={refresher}>Apply Filters</Button>
                    <Button onPress={handleClearFilter}>Clear Filters</Button>
                </Menu> */}
            </>} />
            <View style={globalStyles.container}>
                <Card style={{ ...globalStyles.card, marginVertical: 20 }}>
                    <Card.Title titleStyle={{ fontWeight: "bold" }} title={`Outstanding Balance: ${outstandingBalance}EGP`} />
                    <Card.Content>
                        <Text>To clear balance contact Mr Andrew Nabil</Text>
                    </Card.Content>
                    <Card.Actions>
                        <>
                            <IconButton icon="whatsapp" onPress={() => Linking.openURL("https://wa.me/201222817503")} />
                            <IconButton icon="phone" onPress={() => Linking.openURL("tel:201222817503")} />
                        </>
                    </Card.Actions>

                </Card>
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 2 }}>Date</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Amount</DataTable.Title>
                        <DataTable.Title style={{ flex: 2 }}>Status</DataTable.Title>
                    </DataTable.Header>

                    {payments?.map((payment) => (
                        <DataTable.Row key={payment._id}>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{moment(payment.createdAt).format("DD/MM/YYYY")}</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.amount}EGP</Text></DataTable.Cell>
                            <DataTable.Cell style={{ flex: 2 }}><Text>{payment.status}</Text></DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state.auth?.user,
    payments: state?.records?.payments?.docs,
    outstandingBalance: state?.records?.outstandingBalance,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getTraineePayments
};

export default connect(mapStateToProps, mapDispatchToProps)(TraineePayments);