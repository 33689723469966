import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import { websocketMiddleware } from "./middlewares/websocket";

const inintialState = {};

const middleWare = [thunk, websocketMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware: middleWare,
});

export default store;
