import React, { useState } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { TextInput, Button, Snackbar, Chip, DataTable, Divider, IconButton } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import Papa from 'papaparse';
import { addPlayersToTeam, addTeam } from '../../app/store/actions/adminActions';
import { connect } from 'react-redux';
import globalStyles from '../../app/components/Styles';
import { useNavigation } from '@react-navigation/native';
import AppBar from '../../AppBar';

const AddPlayers = ({ addPlayersToTeam, route }) => {

    const [players, setPlayers] = useState([]);
    const [newPlayer, setNewPlayer] = useState({
        name: "",
        position: "",
        number: ""
    });
    const [file, setFile] = useState(null);
    const { team } = route.params;
    const navigation = useNavigation();

    const handleAddToTeam = async () => {
        try {
            addPlayersToTeam({ teamId: team._id, players }, navigation);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePlayerDelete = (idx) => {
        const newPlayers = players.filter((val, i) => i !== idx)
        setPlayers(newPlayers)
    };

    const handlePlayerAdd = () => {
        if (newPlayer.name !== "" && newPlayer.number !== "") {
            setPlayers([...players, newPlayer])
            setNewPlayer({ name: "", position: "", number: "" })
        }
    }

    const handleUploadCsv = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync({
                type: 'text/csv',
            });
            if (result.type === 'success') {
                const csv = await fetch(result.uri).then((response) => response.text());
                setFile(result.name);
                const parsed = Papa.parse(csv, { header: true });
                const playersToBe = parsed.data.filter((player) => player.name && player.position && player.number)
                console.log(playersToBe)
                setPlayers(playersToBe);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title={`Add players to ${team.name}`} />
            <View style={{ padding: 30 }}>
                <Button style={{ marginBottom: 8 }} mode="contained" onPress={handleUploadCsv}>
                    Upload players CSV
                </Button>
                {file && <Chip icon="file" style={{ width: "150px" }} onClose={() => { setFile(null); setPlayers([]) }}>{file}</Chip>}
                <Divider />
                <Text style={{ textAlign: "center", fontWeight: "bold" }}>Check Players</Text>
                <Divider />
                <DataTable style={globalStyles.table}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }} numeric>Number</DataTable.Title>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Name</DataTable.Title>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Position</DataTable.Title>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Action</DataTable.Title>
                    </DataTable.Header>

                    {players?.map((player, idx) => (
                        <DataTable.Row key={idx}>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.number}</Text></View>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.name}</Text></View>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.position}</Text></View>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="delete" onPress={() => handlePlayerDelete(idx)} /></View>
                        </DataTable.Row>
                    ))
                    }
                    <View style={{ flexDirection: 'row' }}>
                        <TextInput
                            mode="outlined"
                            style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
                            label="Player Number"
                            keyboardType="numeric"
                            value={newPlayer.number}
                            onChangeText={(text) => setNewPlayer({ ...newPlayer, number: text })}
                        />
                        <TextInput
                            mode="outlined"
                            style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
                            label="Player Name"
                            value={newPlayer.name}
                            onChangeText={(text) => setNewPlayer({ ...newPlayer, name: text })}
                        />
                        <TextInput
                            mode="outlined"
                            style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
                            label="Player Position"
                            value={newPlayer.position}
                            onChangeText={(text) => setNewPlayer({ ...newPlayer, position: text })}
                        />

                        <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="plus-circle-outline" onPress={handlePlayerAdd} /></View>
                    </View>
                </DataTable >
                <Button style={{ marginBottom: 8 }} mode="contained" onPress={handleAddToTeam}>
                    Add players
                </Button>
            </View>
        </ScrollView>
    );
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    addPlayersToTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayers);
