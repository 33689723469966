import React from 'react';
import { Appbar, DefaultTheme, ThemeProvider } from 'react-native-paper';

const AppBar = ({ title, navigation, action, noBack }) => {
  return (
    <Appbar.Header>
      {!noBack && <Appbar.BackAction color='white' onPress={() => navigation.goBack()} />}
      <Appbar.Content color='white' title={title} />
      <ThemeProvider
        theme={{
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            primary: '#ffffff',
            background: '000B26',
            onBackground: '#ffffff',
            secondaryContainer: '#0839b486',
            onSecondaryContainer: '#ffffff',
            surface: '#000B26',
            onSurface: '#ffffff',
            onSurfaceVariant: '#ffffff',
            elevation: {
              ...DefaultTheme.colors.elevation,
              level2: "#000B26",
            }
          },
        }}>
        {action}
      </ThemeProvider>
    </Appbar.Header>
  );
};

export default AppBar;
