import * as React from 'react';
import { Avatar, Button, Card, IconButton } from 'react-native-paper';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import { deleteTournament } from '../store/actions/adminActions';
import { getTournaments } from "../store/actions/userActions"
import AlertDialog from './AlertDialog';

export const TournamentCard = ({ tournament, navigation, token, deleteTournament, getTournaments, action, setAction }) => {
    const role = token?.split(" ")[0]
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const handleDelete = () => {
        deleteTournament({ tournamentId: tournament._id })
        hideModal()
        setAction(!action)
    }

    return (
        <>
            <AlertDialog visible={visible} handleAccept={handleDelete} handleClose={hideModal} message={`Are you sure you want to delete ${tournament.name}?`} okText={"CONFIRM"} />
            <Card style={defaultStyles.card} onPress={() => { navigation.navigate("Tournament", { tournamentId: tournament._id }) }}>
                <Card.Title
                    title={tournament.name}
                    subtitle={tournament.sport.name}
                    left={(props) => <Avatar.Icon {...props} icon={tournament.type === "Knockout" ? "tournament" : (tournament.type === "League" ? "table" : "trophy")} />}
                />
                {role && role === "Super" &&
                    <Card.Actions>
                        <Button onPress={showModal} icon="delete">Delete</Button>
                    </Card.Actions>
                }
            </Card>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token
});

const mapDispatchToProps = {
    deleteTournament,
    getTournaments
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentCard);