import React, { useEffect, useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text, ActivityIndicator, Divider, DataTable, ThemeProvider, DefaultTheme } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { addTournament, listOlympiads } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../AppBar";
import { getSports } from "../../app/store/actions/userActions";
import DropDown from "react-native-paper-dropdown";

const CreateTournament = ({ addTournament, getSports, sports, listOlympiads, olympiads, isLoading }) => {
    const [name, setName] = useState("");
    const [sport, setSport] = useState(null);
    const [olympiad, setOlympiad] = useState("");
    const [positionalPoints, setPositionalPoints] = useState([]);
    const [type, setType] = useState("League");
    const [publicTournament, setPublicTournament] = useState(true);
    const [maxTeamNumber, setMaxTeamNumber] = useState("");
    const [maxTeamSize, setMaxTeamSize] = useState("");
    const [minTeamSize, setMinTeamSize] = useState("");
    const [joiningPrice, setJoiningPrice] = useState("");
    const [location, setLocation] = useState("");
    const [organizedFor, setOrganizedFor] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [matchDefaults, setMatchDefaults] = useState({
        duration: undefined,
        date: undefined
    })
    const {duration, date} = matchDefaults
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDown2, setShowDropDown2] = useState(false);

    const navigation = useNavigation();

    useEffect(() => {
        getSports()
        listOlympiads()
    }, [])

    if (olympiads === undefined || olympiads.docs || sports === undefined) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    const handleChangePositionalPoints = (text, idx) => {
        const points = [...positionalPoints]
        points[idx] = parseInt(text) || ""
        setPositionalPoints(points)
    }

    const handleCreateTournament = () => {
        const creation = {
            name,
            sport,
            olympiad,
            positionalPoints,
            type,
            publicTournament,
            maxTeamNumber,
            maxTeamSize,
            minTeamSize,
            joiningPrice,
            location,
            organizedFor,
            description,
            startDate,
            matchDefaults,
            endDate,
        }
        addTournament({ creation }, navigation)
    };

    const handleMaxTeamNumberChange = (text) => {
        setMaxTeamNumber(text)
        setPositionalPoints(new Array(parseInt(text) || 0).fill(""))
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title="Create Tournament" />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Name"
                    value={name}
                    onChangeText={(text) => setName(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Max Team Number"
                    value={maxTeamNumber}
                    keyboardType="numeric"
                    onChangeText={handleMaxTeamNumberChange}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Max Team Size"
                    value={maxTeamSize}
                    keyboardType="numeric"
                    onChangeText={(text) => setMaxTeamSize(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Min Team Size"
                    value={minTeamSize}
                    keyboardType="numeric"
                    onChangeText={(text) => setMinTeamSize(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Joining Price"
                    value={joiningPrice}
                    keyboardType="numeric"
                    onChangeText={(text) => setJoiningPrice(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Location"
                    value={location}
                    onChangeText={(text) => setLocation(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Organized For"
                    value={organizedFor}
                    onChangeText={(text) => setOrganizedFor(text)}
                />
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Description"
                    value={description}
                    onChangeText={(text) => setDescription(text)}
                />
                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Start Date"
                        value={startDate}
                        onChange={(d) => setStartDate(d)}
                        inputMode="start"
                    />
                </ThemeProvider>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="End Date"
                        value={endDate}
                        onChange={(d) => setEndDate(d)}
                        inputMode="end"
                    />
                </ThemeProvider>

                {sports && <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Sport"}
                    mode={"outlined"}
                    visible={showDropDown}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={sport}
                    setValue={setSport}
                    list={sports?.map((sport) => {
                        return {
                            label: sport.name,
                            value: sport._id
                        }
                    })}
                />}
                {olympiads && <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Olympics (Sports Day)"}
                    mode={"outlined"}
                    visible={showDropDown2}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown2(true)}
                    onDismiss={() => setShowDropDown2(false)}
                    value={olympiad}
                    setValue={setOlympiad}
                    list={[...(olympiads?.map((olympic) => {
                        return {
                            label: olympic.name,
                            value: olympic._id
                        }
                    })), { label: "None", value: null }]}
                />}
                {
                    olympiad && (
                        <View style={{ margin: 15 }}>
                            <DataTable style={globalStyles.table}>
                                <DataTable.Header style={globalStyles.header}>
                                    <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Position</DataTable.Title>
                                    <DataTable.Title style={{ flex: 4, justifyContent: "center", alignItems: "center" }} >Points</DataTable.Title>
                                </DataTable.Header>
                                {
                                    positionalPoints.map((points, idx) => {
                                        return (
                                            <View style={{ flexDirection: 'row' }} key={idx}>
                                                <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ flex: 2 }}>{idx + 1}</Text></View>
                                                <TextInput
                                                    mode="outlined"
                                                    style={{ flex: 4 }}
                                                    label="Points"
                                                    value={positionalPoints[idx]}
                                                    keyboardType="numeric"
                                                    onChangeText={(text) => handleChangePositionalPoints(text, idx)}
                                                />
                                            </View>
                                        )
                                    })
                                }
                            </DataTable>
                        </View>
                    )
                }
                <Text>Tournament Type</Text>
                <RadioButton.Group
                    onValueChange={(newValue) => setType(newValue)}
                    value={type}
                >
                    <RadioButton.Item label="League" value="League" />
                    <RadioButton.Item label="Knockout" value="Knockout" />
                    <RadioButton.Item
                        label="Group-Knockout"
                        value="Group-Knockout"
                    />
                </RadioButton.Group>
                <Divider />
                <Text>Tournament Privacy</Text>
                <RadioButton.Group
                    onValueChange={(value) => setPublicTournament(value)}
                    value={publicTournament}
                >
                    <RadioButton.Item label="Public" value={true} />
                    <RadioButton.Item label="Private" value={false} />
                </RadioButton.Group>
                <Divider/>
                <Text>Match Defaults</Text>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Default Duration"
                    inputMode="numeric"
                    value={duration}
                    onChangeText={(text) => setMatchDefaults({...matchDefaults, duration: text})}
                />
                <DatePickerInput
                    locale="en-GB"
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Defaults Matches Date"
                    value={date}
                    onChange={(d) => setMatchDefaults({...matchDefaults, date: d})}
                    inputMode="start"
                />
                <Button mode="contained" onPress={handleCreateTournament}>
                    Create
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    sports: state?.records?.sports,
    olympiads: state?.records?.olympics,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    addTournament,
    getSports,
    listOlympiads
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);