import React, { useState } from "react";
import { View, ScrollView, Text } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import { addMatch, updateMatch } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import { getPlayers, getSport } from "../../app/store/actions/userActions";
import DropDown from "react-native-paper-dropdown";
import AppBar from "../../AppBar";
import { useEffect } from "react";

const MatchUpdate = ({ updateMatch, getPlayers, isLoading, isWaiting, players, route, getSport, sport }) => {

    const navigation = useNavigation();
    const { match } = route.params;

    const [type, setType] = useState("Goal");
    const [cautionType, setCautionType] = useState();
    const [scored, setScored] = useState(1);
    const [team, setTeam] = useState("");
    const [player, setPlayer] = useState(undefined);
    const [time, setTime] = useState(moment(moment() - moment(match.startedAt)).minutes().toString());
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDown1, setShowDropDown1] = useState(false);
    const [showDropDown2, setShowDropDown2] = useState(false);
    const [showDropDown3, setShowDropDown3] = useState(false);

    useEffect(() => {
        getSport({ sportId: match.tournament.sport })
    }, [])

    const handleSetTeam = (value) => {
        getPlayers({ teamId: value, matchId: match._id })
        setTeam(value)
    }

    const handleCreateMatch = () => {
        const update = {
            type,
            cautionType,
            team,
            player,
            time,
            scored,
            value: scored,
            match: match._id
        }
        console.log({ update, matchId: match._id })
        updateMatch({ update, matchId: match._id }, navigation)
    };

    if (isLoading || isWaiting) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title="Update Match" />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Event Time (Minutes)"
                    value={`${time}`}
                    keyboardType="numeric"
                    onChangeText={(text) => setTime(text)}
                />
                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Team"}
                    mode={"outlined"}
                    visible={showDropDown}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={team}
                    setValue={handleSetTeam}
                    list={[
                        { label: match.teamA.name, value: match.teamA._id },
                        { label: match.teamB.name, value: match.teamB._id }
                    ]}
                />
                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Event"}
                    mode={"outlined"}
                    visible={showDropDown1}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown1(true)}
                    onDismiss={() => setShowDropDown1(false)}
                    value={type}
                    setValue={setType}
                    list={[
                        { label: "Goal", value: "Goal" },
                        { label: "Assist", value: "Assist" },
                        { label: "Caution", value: "Caution" },
                        { label: "Own Goal", value: "Own Goal" },
                    ]}
                />
                {type === "Goal" && sport?.selfDefineScore &&
                    <TextInput
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Points Scored"
                        value={scored}
                        keyboardType="numeric"
                        onChangeText={(text) => setScored(text)}
                    />
                }
                {type === "Caution" &&
                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Caution Type"}
                        mode={"outlined"}
                        visible={showDropDown2}
                        dropDownStyle={{ marginVertical: 4 }}
                        showDropDown={() => setShowDropDown2(true)}
                        onDismiss={() => setShowDropDown2(false)}
                        value={cautionType}
                        setValue={setCautionType}
                        list={[
                            { label: "Red Card", value: "Red Card" },
                            { label: "Yellow Card", value: "Yellow Card" },
                            { label: "Second Yellow Card", value: "Second Yellow Card" },
                            { label: "Suspension", value: "Suspension" },
                        ]}
                    />
                }
                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Player"}
                    mode={"outlined"}
                    visible={showDropDown3}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown3(true)}
                    onDismiss={() => setShowDropDown3(false)}
                    value={player}
                    setValue={setPlayer}
                    list={Array.isArray(players) && players?.map((player) => {
                        return (player.isBanned?.length === 0 && player.hasRed?.length === 0) && { label: `${player.player.name}${player.hasYellow[0] ? " (Yellow Card)":""}`, value: player.player._id } 
                    }) || [{ label: "None", value: "None" }]}
                />
                <Button mode="contained" onPress={handleCreateMatch}>
                    Add Update
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.records?.isLoading,
    isWaiting: state?.wait?.isLoading,
    players: state?.records?.players,
    sport: state?.records?.sport,
});

const mapDispatchToProps = {
    getPlayers,
    updateMatch,
    getSport
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchUpdate);