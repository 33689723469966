import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef, useCallback } from 'react';
import { Text, View, ScrollView, RefreshControl } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, DefaultTheme, Divider, IconButton, Menu, TextInput, ThemeProvider } from 'react-native-paper';
import { getMatches } from '../../app/store/actions/userActions';
import { connectWebSocket, disconnectWebSocket } from '../../app/store/actions/websocketActions';
import globalStyles from "../../app/components/Styles";
import TournamentDrawer from '../../app/components/TournamentDrawer';
import moment from 'moment';
import AppBar from '../../AppBar';
import { getSports } from "../../app/store/actions/userActions";
import DropDown from 'react-native-paper-dropdown';
import { DatePickerModal } from 'react-native-paper-dates';
import { AntDesign } from '@expo/vector-icons';

export const Matches = ({ user, getMatches, connectWebSocket, sportsLoading, disconnectWebSocket, getSports, sports, tournaments, isLoading, followed }) => {

    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const [visible, setVisible] = React.useState(false);
    const openMenu = () => {
        getSports();
        setVisible(true);
    };
    const closeMenu = () => setVisible(false);
    const [date, setDate] = React.useState(moment())
    const [dateVisible, setDateVisible] = React.useState(false)
    const [action, setAction] = useState(false)
    const toggleSetDate = () => {
        setDateVisible(!dateVisible);
    };
    const onDismiss = React.useCallback(() => {
        setDateVisible(false)
    }, [setDateVisible])

    const onConfirm = React.useCallback(
        ({ date: newDate }) => {
            setDateVisible(false);
            setDate(moment(newDate));
            getMatches({ page: 1, limit: 100, date: moment(newDate).format("YYYY-MM-DD"), sport })
        },
        [setDateVisible]
    );
    const [sport, setSport] = React.useState(null)
    const [showDropDown, setShowDropDown] = useState(false);

    const refresher = async () => {
        const device = await Device.getDeviceTypeAsync()
        if (device !== Device.DeviceType.DESKTOP) {
            connectWebSocket()
        }
        getMatches({ page: 1, limit: 100, date: date.format("YYYY-MM-DD") })
    }

    useEffect(() => {
        if (!isLoading && !tournaments) {
            refresher()
        }
    }, [user, action])

    const handleSearchFilter = () => {
        getMatches({ page: 1, limit: 100, date: date.format("YYYY-MM-DD"), sport })
    }

    const handleClearFilter = () => {
        setSport(null)
        setDate(moment())
        getMatches({ page: 1, limit: 100, date: moment().format("YYYY-MM-DD") })
    }

    return (
        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar title="Matches" noBack action={<><IconButton icon="calendar" onPress={toggleSetDate} />
                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerModal
                        locale='en-GB'
                        visible={dateVisible}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        inputFormat='YYYY-MM-DD'
                        mode='single'
                        label='Date'
                        value={date}
                    />
                </ThemeProvider>

                <Menu
                    visible={visible}
                    onDismiss={closeMenu}
                    style={{ width: 200 }}
                    anchor={<Appbar.Action icon="filter-outline" id="filter-menu" onPress={openMenu} />}>
                    {sportsLoading ? <ActivityIndicator /> : <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Sport"}
                        mode={"outlined"}
                        visible={showDropDown}
                        dropDownStyle={{ marginVertical: 4 }}
                        showDropDown={() => setShowDropDown(true)}
                        onDismiss={() => setShowDropDown(false)}
                        value={sport}
                        setValue={setSport}
                        list={sports?.map((sport) => {
                            return {
                                label: sport.name,
                                value: sport._id
                            }
                        })}
                    />}
                    <Divider />
                    <Button onPress={handleSearchFilter}>Apply Filters</Button>
                    <Button onPress={handleClearFilter}>Clear Filters</Button>
                </Menu></>} />

            <View style={{ ...globalStyles.container, marginTop: 50 }}>
                {
                    isLoading && (
                        <View style={globalStyles.container}>
                            <ActivityIndicator />
                        </View>
                    )}
                {followed?.length > 0 && <TournamentDrawer tournamentName={"Following"} matches={followed} navigation={navigation} action={action} setAction={setAction} />}
                {
                    tournaments && Object.keys(tournaments).map((key, idx) => {
                        return <TournamentDrawer key={idx} navigation={navigation} tournamentName={key} matches={tournaments[key]} action={action} setAction={setAction} />
                    })
                }
                {
                    tournaments && Object.keys(tournaments).length === 0 && (
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <AntDesign name="dropbox" size={100} color="black" />
                            <Text style={{ fontSize: 40, textAlign: "center" }}>No Data</Text>
                        </View>
                    )
                }
            </View>
        </ScrollView>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    followed: state?.matches?.results?.followed,
    tournaments: state?.matches?.results?.groupedMatches,
    isLoading: state?.matches?.isLoading,
    sports: state?.records?.sports,
    sportsLoading: state?.records?.isLoading
});

const mapDispatchToProps = {
    getMatches,
    connectWebSocket,
    disconnectWebSocket,
    getSports
};

export default connect(mapStateToProps, mapDispatchToProps)(Matches);