import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, FOLLOW_SUCCESS } from "../actions/types";
import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  isError: false,
};

export default function store(state = initialState, action) {
  const { type, payload, token } = action;
  switch (type) {
    case CHANGE:
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        token: token,
        isLoading: false,
        isError: false,
      };
    case FOLLOW_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isError: false,
      };
    case LOGIN_FAIL:
      async () => {
        await AsyncStorage.removeItem("ROARUser");
        await AsyncStorage.removeItem("ROARToken");
      }
      return {
        ...state,
        user: null,
        token: null,
        isLoading: false,
        isError: true,
      };
    case CHANGE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case LOGOUT_SUCCESS:
      async () => {
        await AsyncStorage.removeItem("ROARUser");
        await AsyncStorage.removeItem("ROARToken");
      }
      return {
        ...state,
        user: null,
        token: null,
        isLoading: false,
        isError: false,
      }
    default:
      return state;
  }
}