import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { AddTeamPlayers, CreateMatch, CreateOlympics, EndorStartMatch, Match, MatchUpdate, Olympics, CreateTeamOlympiad, SingleOlympiad, SingleTeam, Tournament, Tournaments, CreateTeam, EditMatchEvent, EditMatch } from "../Pages";
import { NavigationContainer } from "@react-navigation/native";

const Stack = createStackNavigator();

export default function OlympicsRouter({ jumpTo }) {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Olympics">
        <Stack.Screen name="Olympics" options={{ headerShown: false, jumpTo }} component={Olympics} />
        <Stack.Screen name="CreateTeamOlympiad" options={{ headerShown: false }} component={CreateTeamOlympiad} />
        <Stack.Screen name="CreateTeam" options={{ headerShown: false }} component={CreateTeam} />
        <Stack.Screen name="CreateMatch" options={{ headerShown: false }} component={CreateMatch} />
        <Stack.Screen name="CreateOlympics" options={{ headerShown: false }} component={CreateOlympics} />
        <Stack.Screen name="Tournament" options={{ headerShown: false }} component={Tournament} />
        <Stack.Screen name="UpdateMatch" options={{ headerShown: false }} component={MatchUpdate} />
        <Stack.Screen name="EditMatchEvent" options={{ headerShown: false }} component={EditMatchEvent} />
        <Stack.Screen name="EndorStartMatch" options={{ headerShown: false }} component={EndorStartMatch} />
        <Stack.Screen name="Match" options={{ headerShown: false }} component={Match} />
        <Stack.Screen name="Olympiad" options={{ headerShown: false }} component={SingleOlympiad} />
        <Stack.Screen name="AddPlayers" options={{ headerShown: false }} component={AddTeamPlayers} />
        <Stack.Screen name="Team" options={{ headerShown: false }} component={SingleTeam} />
        <Stack.Screen name="EditMatch" options={{ headerShown: false }} component={EditMatch} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
