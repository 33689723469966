import React, { useEffect, useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text, ActivityIndicator, Menu, Appbar, DataTable, IconButton } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../../app/components/Styles";
import { Divider } from '@rneui/themed';
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../../AppBar";
import DropDown from "react-native-paper-dropdown";
import { createAcademy } from "../../../app/store/actions/superActions";
import { getCoaches, getSports } from "../../../app/store/actions/userActions";
import { PaperSelect } from "react-native-paper-select";

const CreateAcademy = ({ createAcademy, getSports, sports, getCoaches, coaches, isLoading }) => {

    const navigation = useNavigation();
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDown1, setShowDropDown1] = useState(false);
    const [showDropDown2, setShowDropDown2] = useState(false);
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const [formData, setFormData] = useState({
        sport: undefined,
        title: "",
        description: "",
        minAge: "0",
        maxAge: "100",
        gender: "both",
        paymentScheme: {

        },
        coachIds: [],
        defaults: {
            location: ""
        }
    });

    const [selectedCoaches, setSelectedCoaches] = useState([])

    const handleChangeFormData = (name, val) => {
        setFormData({ ...formData, [name]: val })
    }

    const [newSessions, setNewSessions] = useState({
        sessions: "",
        price: ""
    })

    const handleSessionsDelete = (sessions) => {
        handleChangeFormData("paymentScheme", { ...formData.paymentScheme, [sessions]: undefined })
    };

    const handleSessionsAdd = () => {
        if (newSessions.sessions !== "" && newSessions.price !== "") {
            handleChangeFormData("paymentScheme", { ...formData.paymentScheme, [newSessions.sessions]: newSessions.price })
            setNewSessions({ sessions: "", price: "" })
        }
    }


    const handleCreateAcademy = () => {
        createAcademy({ creation: { ...formData, coaches: formData.coachIds } }, navigation)
    };

    useEffect(() => {
        getSports()
        getCoaches({ page: 1, limit: 500, name: "" })
    }, [])


    if (isLoading || !coaches || !sports) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView>
            <AppBar navigation={navigation} title={"Create Academy"} action={<>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    <Menu.Item onPress={() => { navigation.navigate("CreateAcademy"); closeMenu() }} leadingIcon="plus-circle" title="Create Academy" />
                    <Menu.Item onPress={() => { navigation.navigate("CreateCoach"); closeMenu() }} leadingIcon="plus-circle" title="Create Coach" />
                    <Menu.Item onPress={() => { navigation.navigate("Coaches"); closeMenu() }} leadingIcon="whistle" title="View Coaches" />
                    <Menu.Item onPress={() => { navigation.navigate("SuperPayments"); closeMenu() }} leadingIcon="receipt" title="View Payments" />
                    <Menu.Item onPress={() => { navigation.navigate("Payouts"); closeMenu() }} leadingIcon="cash-fast" title="View Payouts" />
                </Menu>
            </>} />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Title*"
                    value={formData.title}
                    onChangeText={(text) => handleChangeFormData("title", text)}
                />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4, height: 100 }}
                    label="Description*"
                    multiline
                    numberOfLines={5}
                    value={formData.description}
                    onChangeText={(text) => handleChangeFormData("description", text)}
                />

                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Sport*"}
                    mode={"outlined"}
                    visible={showDropDown}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={formData.sport}
                    setValue={(val) => handleChangeFormData("sport", val)}
                    list={sports && sports.map((sport) => {
                        return {
                            label: sport.name,
                            value: sport._id
                        }
                    })}
                />

                <DataTable style={{ ...globalStyles.table, marginTop: 10 }}>
                    <DataTable.Header style={globalStyles.header}>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }} numeric>Number of sessions</DataTable.Title>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Price per session</DataTable.Title>
                        <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Action</DataTable.Title>
                    </DataTable.Header>

                    {Object.keys(formData.paymentScheme)?.map((sessions) => (
                        <DataTable.Row key={sessions}>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{sessions}</Text></View>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{formData.paymentScheme[sessions]}EGP</Text></View>
                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="delete" onPress={() => handleSessionsDelete(sessions)} /></View>
                        </DataTable.Row>
                    ))}
                    <View style={{ flexDirection: 'row' }}>
                        <TextInput
                            mode="outlined"
                            style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
                            label="Sessions"
                            keyboardType="numeric"
                            value={newSessions.sessions}
                            onChangeText={(text) => setNewSessions({ price: newSessions.price, sessions: text })}
                        />
                        <TextInput
                            mode="outlined"
                            style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
                            label="Price"
                            keyboardType="numeric"
                            value={newSessions.price}
                            onChangeText={(text) => setNewSessions({ sessions: newSessions.sessions, price: text })}
                        />

                        <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="plus-circle-outline" onPress={handleSessionsAdd} /></View>
                    </View>
                </DataTable >

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Minumum Age"
                    value={formData.minAge}
                    inputMode="numeric"
                    onChangeText={(text) => handleChangeFormData("minAge", text)}
                />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Maximum Age"
                    value={formData.maxAge}
                    inputMode="numeric"
                    onChangeText={(text) => handleChangeFormData("maxAge", text)}
                />

                <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                    label={"Gender"}
                    mode={"outlined"}
                    visible={showDropDown1}
                    dropDownStyle={{ marginVertical: 4 }}
                    showDropDown={() => setShowDropDown1(true)}
                    onDismiss={() => setShowDropDown1(false)}
                    value={formData.gender}
                    setValue={(val) => handleChangeFormData("gender", val)}
                    list={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                        { label: "Both", value: "both" },
                    ]}
                />

                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Location URL"
                    value={formData.defaults.location}
                    onChangeText={(text) => handleChangeFormData("defaults", { location: text })}
                />


                <PaperSelect
                    label="Coaches"
                    textInputStyle={{ marginTop: 10 }}
                    value={selectedCoaches[0]?.value}
                    onSelection={(value) => { handleChangeFormData("coachIds", value.selectedList.map((coach) => { return coach._id })); setSelectedCoaches(value.selectedList) }}
                    arrayList={coaches && coaches.map((coach) => {
                        return {
                            value: coach.name,
                            _id: coach.coach._id
                        }
                    })}
                    selectedArrayList={[...selectedCoaches]}
                    multiEnable={true}
                />

                <Button mode="contained" style={{ marginVertical: 10 }} onPress={handleCreateAcademy}>
                    Create
                </Button>
            </View >
        </ScrollView >
    );
}
const mapStateToProps = (state) => ({
    sports: state?.records?.sports,
    coaches: state?.records?.coaches?.docs,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading
});

const mapDispatchToProps = {
    createAcademy, getSports, getCoaches
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAcademy);