import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { Profile, Login, Matches, Match, MatchUpdate, EndorStartMatch, EditMatchEvent, EditMatch } from "../Pages";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

const Stack = createStackNavigator();

export default function MatchesRouter({ jumpTo }) {

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Matches">
        <Stack.Screen name="Matches" options={{ headerShown: false, route: {jumpTo} }} component={Matches} />
        <Stack.Screen name="Match" options={{ headerShown: false }} component={Match} />
        <Stack.Screen name="UpdateMatch" options={{ headerShown: false }} component={MatchUpdate} />
        <Stack.Screen name="EditMatch" options={{ headerShown: false }} component={EditMatch} />
        <Stack.Screen name="EditMatchEvent" options={{ headerShown: false }} component={EditMatchEvent} />
        <Stack.Screen name="EndorStartMatch" options={{ headerShown: false }} component={EndorStartMatch} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
