import React, { useState } from "react";
import { View, ScrollView, SafeAreaView } from "react-native";
import { TextInput, Button, RadioButton, Text } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import { addSport } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput } from "react-native-paper-dates";
import AppBar from "../../AppBar";

const CreateSport = ({ addSport }) => {
    const [name, setName] = useState("");
    const [winPoints, setWinPoints] = useState("");
    const [drawPoints, setDrawPoints] = useState("");
    const [losePoints, setLosePoints] = useState("");
    const [selfDefinePoints, setSelfDefinePoints] = useState(false);
    const [selfDefineScore, setSelfDefineScore] = useState(false);

    const navigation = useNavigation();

    const handleCreateSport = () => {
        const creation = {
            name,
            winPoints,
            drawPoints,
            losePoints,
            selfDefinePoints,
            selfDefineScore
        }
        addSport({ creation }, navigation)
    };

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
            <AppBar navigation={navigation} title="Create Sport" />
            <View style={{ padding: 30 }}>
                <TextInput
                    mode="outlined"
                    style={{ marginVertical: 4 }}
                    label="Name"
                    value={name}
                    onChangeText={(text) => setName(text)}
                />
                <Text>Set Custom Score on Every Goal</Text>
                <RadioButton.Group
                    onValueChange={(value) => setSelfDefineScore(value)}
                    value={selfDefineScore}
                >
                    <RadioButton.Item label="Yes" value={true} />
                    <RadioButton.Item label="No" value={false} />
                </RadioButton.Group>
                <Text>Set Custom Points Every Match</Text>
                <RadioButton.Group
                    onValueChange={(value) => setSelfDefinePoints(value)}
                    value={selfDefinePoints}
                >
                    <RadioButton.Item label="Yes" value={true} />
                    <RadioButton.Item label="No" value={false} />
                </RadioButton.Group>
                {!selfDefinePoints &&
                    <>
                        <TextInput
                            mode="outlined"
                            style={{ marginVertical: 4 }}
                            label="Winner Points"
                            value={winPoints}
                            keyboardType="numeric"
                            onChangeText={(text) => setWinPoints(text)}
                        />
                        <TextInput
                            mode="outlined"
                            style={{ marginVertical: 4 }}
                            label="Draw Points"
                            value={drawPoints}
                            keyboardType="numeric"
                            onChangeText={(text) => setDrawPoints(text)}
                        />
                        <TextInput
                            mode="outlined"
                            style={{ marginVertical: 4 }}
                            label="Loss Points"
                            value={losePoints}
                            keyboardType="numeric"
                            onChangeText={(text) => setLosePoints(text)}
                        />
                    </>}
                <Button mode="contained" onPress={handleCreateSport}>
                    Create
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    addSport
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSport);