import { Provider } from 'react-redux';
import store from './app/store/store';
import 'react-native-gesture-handler';
import { useEffect, useState } from 'react';
import { loadUser } from './app/store/actions/authActions';
import { BottomNavigation, Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import RouterBottom from './RouterBottom';
import AppBar from './AppBar';
import { getAcademies, getMatches, getOlympiads, getTournaments } from './app/store/actions/userActions';
import moment from 'moment';

store.dispatch(loadUser());
store.dispatch(getTournaments({ page: 1, limit: 100, name: "" }));
store.dispatch(getOlympiads({ page: 1, limit: 100, name: "" }));
store.dispatch(getAcademies({ page: 1, limit: 100, title: "" }));
store.dispatch(getMatches({ page: 1, limit: 100, date: moment().format("YYYY-MM-DD") }));

const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#000B26',
    onBackground: '#000B26',
    secondaryContainer: '#ffffff',
    surface: '#000B26',
    elevation: {
      ...DefaultTheme.colors.elevation,
      level2: "#000B26",
    }
  },
};

export default function App() {

  return (
    <Provider store={store}>
      <PaperProvider theme={customTheme}>
        <RouterBottom />
      </PaperProvider>
    </Provider>
  );
}