import * as React from "react";
import { connect } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Text, View, Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { useNavigation } from "@react-navigation/native";
import {
  ActivityIndicator,
  Button,
  DefaultTheme,
  TextInput
} from "react-native-paper";
import { resetPassword } from "../../app/store/actions/authActions";
import globalStyles from "../../app/components/Styles";
import { TabScreen, Tabs, TabsProvider } from "react-native-paper-tabs";
import PhoneInput from "react-native-phone-input";
import AppBar from "../../AppBar";

export const ResetPassword = ({ user, token, resetPassword, signUp, isLoading }) => {
  const navigation = useNavigation();
  const [email, setEmail] = React.useState("");

  const handleReset = async () => {
    resetPassword({ email });
    navigation.navigate("Login")
  };

  useEffect(() => {
    if (user) {
      try {
        window.ReactNativeWebView.postMessage(token);
      } catch (err) {
        console.log(err);
      }
      navigation.navigate("Profile");
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      try {
        window.ReactNativeWebView.postMessage(token);
      } catch (err) {
        console.log(err);
      }
      navigation.goBack();
    }
  }, []);

  if (isLoading) {
    return (
      <View style={globalStyles.container}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <>
      <AppBar navigation={navigation} title={"Reset Password"} />
      <View style={globalStyles.container}>
        <TextInput
          mode="outlined"
          label="Email"
          value={email}
          onChangeText={setEmail}
          style={globalStyles.input}
        />
        <Button mode="contained" onPress={async () => await handleReset()}>
          Reset
        </Button>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  token: state?.auth?.token,
  isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
