import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, Platform, Image } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Appbar, Button, Divider, FAB, Menu, Modal, Portal, TextInput } from 'react-native-paper';
import roarImage from "../../app/components/images/ROAR.png";
import AppBar from '../../AppBar';
import { ScrollView } from 'react-native-gesture-handler';
import QRCode from "react-qr-code";
import { getAcademies } from '../../app/store/actions/userActions';
import { RefreshControl } from 'react-native';
import globalStyles from '../../app/components/Styles';
import AcademyCard from '../../app/components/AcademyCard';

export const Home = ({ user, trainee, coach, getAcademies, isLoading, academies }) => {

    const navigation = useNavigation();
    const [action, setAction] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };

    const [searchBar, setSearchBar] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [menuVisible, setMenuVisible] = React.useState(false);

    const openMenu = () => {
        setMenuVisible(true)
    };

    const closeMenu = () => {
        setMenuVisible(false)
    };

    const openSearch = () => {
        setSearchBar(true)
    }

    const closeSearch = () => {
        setSearchBar(false)
    }


    useEffect(() => {
        getAcademies({ page: 1, limit: 100, title: search })
    }, [action])

    const handleSearchFilter = () => {
        getAcademies({ page: 1, limit: 100, title: search })
    }

    const refresher = async () => {
        getAcademies({ page: 1, limit: 100, title: search })
    }

    return (
        <ScrollView contentContainerStyle={{ justifyContent: "center" }} refreshControl={<RefreshControl onRefresh={refresher} />}>
            <AppBar navigation={navigation} title={"ROAR Academy"} noBack action={<>
                {trainee && <Appbar.Action icon="qrcode" onPress={showModal} />}
                {coach && <Appbar.Action icon="qrcode" onPress={showModal} />}
                {user?.type === "User" && !trainee && !coach && <Appbar.Action icon="account-plus-outline" onPress={() => navigation.navigate("Enroll")} />}
                <Menu
                    visible={searchBar}
                    onDismiss={closeSearch}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="magnify" id="search-menu" onPress={openSearch} />}>
                    <TextInput
                        mode="outlined"
                        label="Search"
                        value={search}
                        onChangeText={text => setSearch(text)}
                        right={<TextInput.Icon onPress={handleSearchFilter} icon="magnify" />}
                    />
                </Menu>
                {user && (trainee || coach || user.type === "Super") && <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    style={{ width: 300 }}
                    anchor={<Appbar.Action icon="menu" id="menu" onPress={openMenu} />}>
                    {trainee && <>
                        <Menu.Item onPress={() => { navigation.navigate("TraineeReservations"); closeMenu() }} leadingIcon="bookmark-check" title="My Reservations" />
                        <Menu.Item onPress={() => { navigation.navigate("TraineePayments"); closeMenu() }} leadingIcon="wallet" title="My Balance" />
                        <Menu.Item onPress={() => { navigation.navigate("TraineeAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                    </>}
                    {coach && <>
                        <Menu.Item onPress={() => { navigation.navigate("CoachProfile"); closeMenu() }} leadingIcon="account" disabled title="My Profile" />
                        <Menu.Item onPress={() => { navigation.navigate("CoachAgenda"); closeMenu() }} leadingIcon="calendar" title="My Agenda" />
                        <Menu.Item onPress={() => { navigation.navigate("CoachAttendance"); closeMenu() }} leadingIcon="account-clock" title="My Attendance" />
                    </>}
                    {user?.type === "Super" && <>
                        <Menu.Item onPress={() => { navigation.navigate("CreateAcademy"); closeMenu() }} leadingIcon="plus-circle" title="Create Academy" />
                        <Menu.Item onPress={() => { navigation.navigate("CreateCoach"); closeMenu() }} leadingIcon="plus-circle" title="Create Coach" />
                        <Menu.Item onPress={() => { navigation.navigate("Coaches"); closeMenu() }} leadingIcon="whistle" title="View Coaches" />
                        <Menu.Item onPress={() => { navigation.navigate("SuperPayments"); closeMenu() }} leadingIcon="receipt" title="View Payments" />
                        <Menu.Item onPress={() => { navigation.navigate("Payouts"); closeMenu() }} leadingIcon="cash-fast" title="View Payouts" />
                    </>}
                </Menu>}
            </>} />
            <View style={{ ...globalStyles.container, marginTop: 5, paddingHorizontal: 20 }}>
                {isLoading && <ActivityIndicator />}
                {!user && (
                    <View style={{ margin: 20 }}>
                        {!trainee && <Button mode="contained" onPress={() => navigation.navigate("Login")}>Login to enroll in academies</Button>}
                    </View>
                )}
                {user?.type === "User" && !coach && !trainee && (
                    <View style={{ margin: 20 }}>
                        <Button mode="contained" onPress={() => navigation.navigate("Enroll")}>Enroll in academies</Button>
                    </View>
                )}
                {academies?.length > 0 && academies.map((academy) => { return <AcademyCard action={action} setAction={setAction} academy={academy} navigation={navigation} key={academy._id} /> })}
                {user?.type === "User" && (
                    <View>
                        <Portal>
                            <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={user?._id}
                                />
                            </Modal>
                        </Portal>
                    </View>
                )}
                {user?.type === "Admin" && (
                    <View>

                    </View>
                )}
            </View>
        </ScrollView>
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    trainee: state?.auth?.user?.trainee,
    coach: state?.auth?.user?.coach,
    isLoading: state?.records?.isLoading,
    academies: state?.records?.academies?.docs
});

const mapDispatchToProps = { getAcademies };

export default connect(mapStateToProps, mapDispatchToProps)(Home);