import { FETCH_MATCHES, FETCH_MATCHES_SUCCESS, FETCH_MATCHES_FAIL, NEW_MATCH_UPDATE } from "../actions/types";

const initialState = {
    results: null,
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_MATCHES:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_MATCHES_SUCCESS:
            return {
                ...state,
                results: payload,
                isLoading: false,
                isError: false,
            };
        case FETCH_MATCHES_FAIL:
            return {
                ...state,
                results: null,
                isLoading: false,
                isError: true,
            };
        case NEW_MATCH_UPDATE:
            let matches = state.results?.groupedMatches
            let matchesFollowed = state.results?.followed
            Object.keys(matches).map((key) => {
                matches[key] = matches[key]?.map((match) => {
                    if (match._id === payload.match._id) {
                        return payload.match
                    } else {
                        return match
                    }
                })
            })
            let newMatchesFollowed = matchesFollowed?.map((match) => {
                if (match._id === payload.match._id) {
                    return payload.match
                } else {
                    return match
                }
            })
            console.log(matches)
            return {
                ...state,
                results: {
                    ...state.results,
                    groupedMatches: matches,
                    followed: newMatchesFollowed
                },
                isLoading: false,
                isError: false,
            };
        default:
            return state;
    }
}