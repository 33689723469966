import * as React from 'react';
import { Avatar, Button, Card, Divider, IconButton, Modal, Portal, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import defaultStyles from "./Styles"
import { connect } from 'react-redux';
import globalStyles from './Styles';
import moment from 'moment';
import { useState } from 'react';
import AlertDialog from './AlertDialog';
import { deleteSport } from '../store/actions/adminActions';

export const SportCard = ({ sport, token, user, action, setAction, deleteSport }) => {
    const role = token?.split(" ")[0]

    const containerStyle = { backgroundColor: 'white', padding: 30, justifyContent: "center", alignItems: "center", margin: 30, borderRadius: 10 };
    const [visible, setVisible] = useState(false)
    const hideModal = () => {
        setVisible(false)
    }
    const showModal = () => {
        setVisible(true)
    }

    const [actionModal, setActionModal] = React.useState(false);
    const showActionModal = () => setActionModal(true);
    const hideActionModal = () => setActionModal(false);

    const handleDelete = () => {
        deleteSport({ sportId: sport._id })
        hideActionModal()
        setAction(!action)
    }

    return (
        <>
            <View>
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                        <Text variant="titleLarge" style={{ fontWeight: "bold", marginBottom: 10 }}>About {sport.name}</Text>
                        <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Custom Score: {sport.selfDefineScore ? "Yes" : "No"}</Text>
                        <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Custom Points: {sport.selfDefinePoints ? "Yes" : "No"}</Text>
                        <Divider style={{ width: "100%", height: 2, marginVertical: 2 }} />
                        {!sport.selfDefinePoints && <>
                            <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Win Points: {sport.winPoints}</Text>
                            <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Draw Points: {sport.drawPoints}</Text>
                            <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Lose Points: {sport.losePoints}</Text>
                            <Divider style={{ width: "100%", height: 2, marginVertical: 2 }} />
                        </>}
                        <Text variant="bodyMedium" style={{ marginBottom: 10 }}>Created: {moment(sport.createdAt).format("ll")}</Text>
                    </Modal>
                </Portal>
            </View>
            <AlertDialog visible={actionModal} handleAccept={handleDelete} handleClose={hideActionModal} message={`Are you sure you want to delete this sport?`} okText={"CONFIRM"} />
            <Card style={{ maxWidth: "80%", ...defaultStyles.card }}>
                <Card.Title titleStyle={{ fontWeight: "bold" }} title={sport.name} />
                <Card.Actions>
                    <Button icon="information" mode="text" onPress={showModal}>More</Button>
                    <Button icon="delete" mode="text" onPress={showActionModal}>Delete</Button>
                </Card.Actions>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    deleteSport
};

export default connect(mapStateToProps, mapDispatchToProps)(SportCard);